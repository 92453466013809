<template>
  <div class="billing-info__inner">
    <div class="form-wrapper-section" v-if="form.f.country_code.model === 'IN'">
      <div class="form-group prompt-box">
        <input
          type="checkbox"
          :checked="isForBusiness"
          @change="toggleIsBusiness" name="behaviour"
        />
        <label @click="toggleIsBusiness">This is for business(for Taxable Invoice)</label>
      </div>
      <div v-if="isForBusiness" class="business-form-group">
        <BillingFormFieldInput
          class="form-group"
          :formField="form.f.company_name"
          @change="onInputChange"
        />
        <BillingFormFieldInput
          class="form-group"
          :formField="form.f.gst_number"
          @change="onChangeGST"
        />
      </div>
    </div>
    <div class="form-wrapper-section">
      <h5>Billing</h5>
      <BillingFormFieldInput
        class="form-group name-box"
        :formField="form.f.contact_name"
        @change="onInputChange"
      />
      <BillingFormFieldInput
        class="form-group email-box"
        :formField="form.f.contact_email"
        @change="onInputChange"
      />
      <BillingFormFieldInput
        v-if="cartPreview.grand_total === 0 && validated"
        class="form-group email-box"
        :formField="form.f.phone_number"
        @change="onInputChange"
      />
      <div
        v-if="(!cartPreview.grand_total) && cartPreviewInput.canChangeRegion"
        class="form-group"
      >
        <label class="field-label">{{form.f.billing_currency.name}}</label>
        <CurrencyDropdown
          :defaultValue="form.f.billing_currency.model"
          :disabled="form.f.billing_currency.disabled"
          :placeholder="'Preferred Billing Region'"
          @change="onCurrencyChange($event)"
        />
      </div>
      <div
        v-else-if="form.f.billing_currency.model === 'inr'"
        class="disabled-country-msg"
      >
        <p>Country is preselected as INDIA for INR currency.</p>
      </div>
      <div class="form-group two-column">
        <BillingFormFieldSelect
          class="left"
          :formField="form.f.country_code"
          :options="BillableCountries"
          @change="onCountryChange"
        />
        <BillingFormFieldSelect
          class="right"
          v-if="form.f.country_code.model === 'IN'"
          :formField="form.f.state"
          :options="indiaStatesList"
          @change="onStateChange"
        />
        <BillingFormFieldInput
          v-else class="right"
          :formField="form.f.state"
          @change="onInputChange"
        />
      </div>
      <div class="form-group two-column">
        <BillingFormFieldInput
          class="left"
          :formField="form.f.city"
          @change="onInputChange"
        />
        <BillingFormFieldInput
          class="right"
          :formField="form.f.zip_code"
          @change="onInputChange"
        />
      </div>
      <BillingFormFieldInput
        class="form-group address-box"
        :formField="form.f.address"
        @change="onInputChange"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { FormHandler, BillableCountries, indiaStatesList } from '@/core/utils/formModels';
import CurrencyDropdown from '@/components/Onboarding/CurrencyDropdown.vue';
import BillingFormFieldInput from '@/components/Payments/BillingFormFieldInput.vue';
import BillingFormFieldSelect from '@/components/Payments/BillingFormFieldSelect.vue';

export default {
  components: {
    BillingFormFieldInput,
    BillingFormFieldSelect,
    CurrencyDropdown,
  },
  props: {
    isAntsProducts: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      cartPreview: (state) => state.payments.cartPreview,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      activeUser: (state) => state.boot.instance.user,
    }),
    isForBusiness() {
      return this.businessToggle || this.form.f.company_name.model || this.form.f.gst_number.model;
    },
    validated() {
      if ((this.form.f.phone_number.model === '' || this.form.f.phone_number.model === null)
        && this.cartPreview.grand_total === 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.f.phone_number.required = true;
        return this.form;
      }
      return this.form;
    },
  },

  data() {
    const fieldsConfig = [
      {
        key: 'company_name',
        name: 'Company',
        formName: 'organization',
        required: false,
        errorMessage: 'Enter the legal name of your Business.',
      },
      {
        key: 'gst_number',
        name: 'GST Number',
        required: false,
        errorMessage: 'This field cannot be empty for Business transactions.',
      },
      {
        key: 'contact_name',
        name: 'Name',
        formName: 'name',
        errorMessage: 'Name cannot be empty.',
      },
      {
        key: 'contact_email',
        name: 'Email',
        formName: 'email',
        type: 'email',
        errorMessage: 'Enter Valid Email Address',
      },
      {
        key: 'phone_number',
        name: 'Phone Number',
        required: false,
        errorMessage: 'Please fill your phone number details',
      },
      {
        key: 'country_code',
        name: 'Country',
        formName: 'country',
        errorMessage: 'Must select a country',
      },
      {
        key: 'billing_currency',
        name: 'Billing Region',
      },
      {
        key: 'state',
        name: 'State',
        formName: 'state',
        errorMessage: 'Enter your State/Province',
      },
      {
        key: 'city',
        name: 'City',
        formName: 'city',
        errorMessage: 'Enter your city name',
      },
      {
        key: 'zip_code',
        name: 'Zipcode',
        formName: 'postal-code',
        errorMessage: 'Enter a valid Zip/PIN code',
      },
      {
        key: 'address',
        name: 'Address',
        formName: 'address',
        errorMessage: 'Please enter billing address',
      },
    ];
    return {
      businessToggle: false,
      form: new FormHandler({ fieldsConfig }),
      BillableCountries,
      indiaStatesList,
    };
  },

  mounted() {
    this.prepareForm();
  },

  methods: {
    prepareForm() {
      this.$store.commit('payments/updateAddressFromPreview');
      this.form.updateInitValue(this.cartPreviewInput.billing);
      this.autoSetCurrencyValues();
      this.setInitialValues();
    },

    setInitialValues() {
      if (!this.form.f.company_name.model) {
        this.form.f.company_name.mode = this.activeUser.company_name;
      }
      if (!this.form.f.contact_name.model) {
        this.form.f.contact_name.model = this.activeUser.name;
      }

      if (!this.form.f.contact_email.model) {
        this.form.f.contact_email.model = this.activeUser.email;
      }
    },

    autoSetCurrencyValues() {
      const currency = this.form.f.billing_currency.model;
      if (currency === 'inr') {
        this.form.f.country_code.model = 'IN';
        this.form.f.country_code.disabled = true;
      } else {
        if (this.form.f.country_code.model === 'IN') {
          this.form.f.country_code.model = '';
        }
        this.form.f.country_code.disabled = false;
      }

      if (!this.cartPreviewInput.canChangeRegion) {
        this.form.f.country_code.disabled = true;
        this.form.f.billing_currency.disabled = true;
      }
    },

    validateCountry() {
      const currency = this.form.f.billing_currency.model;
      const country = this.form.f.country_code.model;
      if (
        (country === 'IN' && currency !== 'inr')
        || (country !== 'IN' && currency === 'inr')
      ) {
        this.form.f.country_code.customErrorMessage = 'For India, billing region must be "India".';
      } else {
        this.form.f.country_code.customErrorMessage = '';
      }
    },

    onCurrencyChange(currency) {
      this.onInputChange(currency);
      this.form.f.billing_currency.model = currency;
      this.autoSetCurrencyValues();
      this.validateCountry();
      this.$store.commit('user/updateActiveCurrency', currency);
      this.$store.dispatch('payments/updateCart', { currency });
    },

    gstValidation() {
      if (this.form.f.state.model) {
        const chosenState = this.form.f.state.model;
        const gstNumber = this.form.f.gst_number.model;
        const textCheck = gstNumber.charAt(13);
        const regionInfo = indiaStatesList.filter((item) => item.key.includes(chosenState));
        const stateCode = gstNumber.substring(0, 2);
        if ((regionInfo[0].code.includes(stateCode)) && (textCheck === 'Z' || textCheck === 'z')) {
          this.form.f.gst_number.customErrorMessage = '';
        } else {
          this.form.f.gst_number.customErrorMessage = 'Please enter a valid GST number';
        }
      }
    },

    onChangeGST(event) {
      this.onInputChange(event);
      this.gstValidation();
    },

    onCountryChange(event) {
      this.onInputChange(event);
      this.validateCountry();
    },

    toggleIsBusiness() {
      this.businessToggle = !this.businessToggle;
      if (!this.businessToggle) {
        this.form.f.company_name.model = '';
        this.form.f.gst_number.model = '';
      }
    },

    isFormValid() {
      return this.form.isValid;
    },

    formData() {
      return this.form.formData();
    },

    onInputChange(event) {
      this.$emit('change', event);
    },

    onStateChange(event) {
      this.$store.dispatch('payments/updateCart', { stateCode: event });
      this.$emit('change', event);
      if (this.form.f.gst_number.model) {
        this.gstValidation();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-wrapper-section {
  padding: size(30);
  border-radius: size(8);
  box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
    0 size(2) size(1) rgba(0, 0, 0, 0.06),
    0 size(1) size(1) rgba(0, 0, 0, 0.08);
  background: var(--rs-white-color);
  margin-bottom: size(20);
}
</style>
