<template>
  <div class="select-plan">
    <div class="intro-section">
      <div class="title-block">
        <h4>Get Started</h4>
        <h2>Plans & Packages</h2>
      </div>
      <div class="form-group currency-dropdown">
        <label class="field-label">Billing Region</label>
        <CurrencyDropdown
          :defaultValue="currency"
          :placeholder="'Preferred Billing Region'"
          @change="updateCurrency($event)"
        />
      </div>
      <div class="image-block">
        <img src="@/assets/payment/plan-img.png" />
      </div>
    </div>
    <div class="plan-wrapper">
      <PackageBasedPricing :currency="currency" :packagePlans="packageInfo"
        @showPopup="showPopup($event)" :isPayment="true"/>
    </div>
    <div class="btn-wrap">
      <rz-request-state :state="createProjectState" />
      <div
        v-if="errorMessage"
        class="form-errors error-field"
      >
        <p class="error-msg">{{errorMessage}}</p>
      </div>
    </div>
    <div class="popup-wrapper" v-if="openPopup" @click.self="closePopup()">
      <div class="popup" id="popup">
        <div class="popup-header">
          <svg @click="closePopup()"><use v-bind="{'xlink:href':'#close-btn'}"></use></svg>
        </div>
       <PackagePopup :planInfo="planInfo" :currency="currency"/>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';
import { FormHandler } from '@/core/utils/formModels';
import { licenseTypes } from '@/store/modules/paygo';
import CurrencyDropdown from '@/components/Onboarding/CurrencyDropdown.vue';
import PackageBasedPricing from '@/components/PackageBasedPricing.vue';
import PackagePopup from '@/components/PackagePopup.vue';

export default {
  components: {
    CurrencyDropdown,
    PackageBasedPricing,
    PackagePopup,
  },

  mixins: allMetaMixins(),
  title: 'Get Started | Roanuz Cricket API',

  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      packageInfo: (state) => state.paygo.package && state.paygo.package.packageInfo,
      createProjectState: (state) => state.payments.createProjectState,
      currency: (state) => state.user.activeCurrency,
    }),
    selectedLicense: {
      get() {
        return this.$store.state.payments.wizard.selectedLicense;
      },
      set(val) {
        this.updateLicense(val);
      },
    },
  },

  data() {
    const fieldsConfig = [
      {
        key: 'project_name',
        name: 'Project Name',
      },
    ];
    return {
      form: new FormHandler({ fieldsConfig }),
      errorMessage: '',
      licenseTypes,
      openPopup: false,
      planInfo: null,
    };
  },

  mounted() {
    this.fetchPackagePricing();
  },


  methods: {
    updateCurrency(currency) {
      this.$store.commit('user/updateActiveCurrency', currency);
    },

    fetchPackagePricing() {
      return this.$store.dispatch('paygo/fetchPackagePricing');
    },

    updateLicense(key) {
      this.$store.commit('payments/updateWizard', { selectedLicense: key });
    },

    onLicenseChange(license) {
      this.$store.commit('payments/updateCartPreviewParam', {
        selectedLicense: license,
      });
    },

    validate() {
      if (!this.wizard.selectedLicense) {
        this.errorMessage = 'Please select a license';
        return false;
      }

      return true;
    },

    moveNextStep() {
      if (this.validate()) {
        this.$store.dispatch('payments/createProject').then((resp) => {
          const params = {
            projectKey: resp.key,
            ...this.wizard.paymentForwardParams,
          };

          this.$router.push({
            name: 'projectWizard',
            params,
          });
        });
      }
    },
    showPopup(plan) {
      this.planInfo = plan;
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'hidden';
    },
    closePopup() {
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'auto';
    },
  },
};
</script>
<style lang="scss">
.select-plan {
  max-width: size(980);
  .form-group {
    &.currency-dropdown {
      width: size(180);
      margin-bottom: size(20);
      .dropdown {
        width: size(180);
        border-radius: size(8);
      }
      .field-label {
        color: var(--rs-black-color);
        padding-bottom: size(10);
      }
    }
  }
  .plan-box {
    @media screen and (min-width: $breakpoint-md) {
      min-height: size(350);
    }
  }
}
</style>
<style lang="scss" scoped>
.intro-section {
  position: relative;
  .title-block {
    h4 {
      font-weight: 500;
      font-size: size(12);
      line-height: size(17);
      color: var(--rs-black-color);
      margin-bottom: size(10);
    }
    h2 {
      margin-bottom: size(40);
    }
  }
}
.image-block {
  display: none;
  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
  position: absolute;
  right: 0;
  bottom: size(-80);
  width: size(300);
  height: size(300);
  img {
    width: size(300);
    height: size(300);
  }
}
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  .popup {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: size(2);
    width: size(300);
    height: size(450);
    overflow: scroll;
    @media screen and (min-width: $breakpoint-md) {
      width: size(680);
      height: auto;
      overflow: auto;
    }
    .popup-header {
      svg {
        position: absolute;
        right: size(20);
        top: size(20);
        width: size(10);
        height: size(10);
        cursor: pointer;
      }
    }
  }
}
</style>
