<template>
  <div class="sign-up signup-page"  :class="[(isAntsProducts ? 'ants-sign-up' : '')]" v-if="dataLoaded">
    <div class="flex" v-on:keyup.enter="createAccount">
      <div class="flex-col-right" :class="[signUpInitiated ? 'initiated' : null]">
        <div class="form-wrapper">
          <h1 class="signup-text">Create your Roanuz account</h1>
          <p class="login-info" v-if="isAntsProducts && !antsQueryParams">
            Already have an account?
            <router-link class="login-info-link"
             :to="{ name: 'login', query: {...$route.query} }">Sign in</router-link>
          </p>
          <p class="login-info" v-else>
            Already have an account?
            <router-link class="login-info-link" :to="{ name: 'login', query: loginParams }">Sign in</router-link>
          </p>
          <div class="register-form">
            <div :class="['form-group', (isAntsProducts ? 'ants-form-group' : ''),
             (formFields.name.showError ? 'error-field' : '')]">
              <label class="field-label" for="fullname">Full name</label>
              <input
                :class="[!formFields.name.showError ? 'tick-icon' : null]"
                type="text"
                v-model="userInfo.name"
                name="fullname"
                id="fullname"
                placeholder=" "
                autocomplete="off"
                autofocus
              />
              <p v-if="formFields.name.showError" class="error-msg">{{formFields.name.errorMsg}}</p>
            </div>
            <div :class="['form-group',
            (isAntsProducts ? 'ants-form-group' : ''),(formFields.email.showError ? 'error-field' : '')]">
              <label class="field-label" for="workemail">Work email</label>
              <input
                :class="[!formFields.email.showError ? 'tick-icon' : null]"
                type="email"
                v-model="userInfo.email"
                name="workemail"
                id="workemail"
                placeholder=""
                autocomplete="off"
              />
              <p v-if="formFields.email.showError" class="error-msg">{{formFields.email.errorMsg}}</p>
            </div>
            <div :class="['form-group password-div',
            (isAntsProducts ? 'ants-form-group' : ''),
             (formFields.password.showError ? 'error-field' : '')]">
              <label class="field-label password" for="password">Password</label>
              <input
                :class="[!formFields.password.showError ? 'tick-icon' : null]"
                type="password"
                v-model="userInfo.password"
                name="password"
                id="password"
                placeholder=" "
                autocomplete="on"
              />
              <p v-if="formFields.password.showError" class="error-msg">{{formFields.password.errorMsg}}</p>
            </div>
            <div :class="['form-group',
            (isAntsProducts ? 'ants-form-group' : ''),
             (formFields.companyName.showError ? 'error-field' : '')]">
              <label class="field-label" for="fullname">Company</label>
              <input
                :class="[!formFields.companyName.showError ? 'tick-icon' : null]"
                type="text"
                v-model="userInfo.company_name"
                name="company"
                id="company"
                placeholder=" "
                autocomplete="off"
              />
              <p v-if="formFields.companyName.showError" class="error-msg">{{formFields.companyName.errorMsg}}</p>
            </div>
            <div :class="['form-group', (isAntsProducts ? 'ants-form-group' : ''),]">
              <label class="field-label" for="fullname">Phone number</label>
              <input
                type="number"
                v-model="userInfo.phone_number"
                id="phoneNumber"
                name="phoneNumber"
                placeholder=" "
                autocomplete="off"
              />
            </div>
            <label class="check-box-group terms-and-conditions">
              By clicking Create Account, you agree to our and
              <span class="link-item">
                <a href="https://www.cricketapi.com/legal/Terms-of-use/"
                target="_blank"> Terms & Conditions</a> and
                <a href="https://www.cricketapi.com/legal/Privacy-Policy/"
                target="_blank">Privacy Policy</a>
              </span>
            </label>
            <div class="alert alert-warning" v-if="errorMessage">
              <p>{{errorMessage}}</p>
            </div>
            <button :disabled="showLoader && validateSubmit"
            :class="[ 'console-btn' , (isAntsProducts ? 'console-btn-ants' : '') ,
            validateSubmit ? 'disabled-btn': '']"
            type="submit" @click="createAccount">
              <div v-if="!loadingState.status.isLoaded">
                <rz-request-state :state="loadingState" />
              </div>
              <span v-if="showSignup">Create Account</span>
            </button>
            <div v-if="!isAntsProducts">
              <button :class="['cancel-btn' , (isAntsProducts ? 'cancel-btn-ants' : '')]"
              @click="back()">Back</button>
            </div>
          </div>
        </div>
      </div>
      <div class="signup-image-block" v-if="!isAntsProducts">
        <img class="bowler-img" src="@/assets/payment/signup-grp1.png" />
        <img class="batsmen-img" src="@/assets/payment/signup-grp2.png" />
        <img class="ring-img" src="@/assets/payment/signup-grp3.png" />
      </div>
      <div class="signup-image-block" v-else>
        <img class="bowler-img" src="@/assets/ants/signup-group-3.png" />
        <img class="batsmen-img" src="@/assets/ants/signup-group-2.png" />
        <img class="ring-img" src="@/assets/ants/signup-group-1.png" />
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import '@/assets/styles/views/form.scss';
</style>

<style lang="scss">
.author-info {
  .image-item,
  img {
    border-radius: 50%;
    max-width: size(55);
    min-width: size(55);
    max-height: size(55);
    min-height: size(55);
  }
}

.flex {
  &-col {
    &-right {
      &.initiated {
          .tick-icon {
            background-image: url('../../assets/tick-icon.svg');
            background-repeat: no-repeat;
            background-position: 98% 50%;
            background-size: size(14) size(10);
          }
          .show {
            max-height: size(24);
          }
          .hide {
            max-height: 0;
          }
        }
    }
  }
}

.check-box-group {
  .link-item {
    display: inline-block;
    .link-plain {
      color: var(--onboard-link-color);
      padding-right: size(15);
      background-image: url('../../assets/link-arrow.svg');
      background-repeat: no-repeat;
      background-position: 100% 50%;
    }
  }
}
.header {
  .user-info {
    height: size(28);
    position: relative;
    & .user-dropdown {
      display: none;
    }
    &:hover .user-dropdown {
      display: block;
    }
    .user-name {
      display: inline-block;
      padding-left: size(13);
      font-size: size(20);
      line-height: size(24);
      color: var(--ca-title-color);
      margin-left: size(0);
      cursor: pointer;
    }
  }
}
.agile {
  width: 100%;
}
.agile__actions {
  margin-top: size(60);
}
.agile__nav-button {
  opacity: 0;
}
.agile__dot {
  margin: 0 size(12) 0 0;
}
.agile__dot button {
  background-color: rgba(79, 55, 200, 0.2);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: size(12);
  width: size(12);
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: var(--onboard-link-color);
}

.image-area {
  .show-container {
    img {
      max-height: size(435);
    }
  }
}

.ants-sign-up {
  .form-group.error-field {
      .error-msg {
        color: var(--rz-ants-error-color);
    }
    input {
      border-color:  var(--rz-ants-error-color);
    }
  }
  .flex-col-right {
  background: var(--rz-ants-color-primary-bg);
  font-family: var(--rz-ants-font);

  .check-box-group.terms-and-conditions {
    color: var(--rs-white-color);

      a {
        color: var(--rz-ants-color-text1);
      }
    }
  }
  .signup-text {
    color: var(--rs-white-color);
  }
  .login-info {
   color: var(--rz-ants-color-text1);
  }
  .login-info-link {
    color: var(--rz-ants-primary-button-bg);
  }
  .ants-form-group {
    label {
      color: var(--rz-ants-color-text1);
    }
    input {
      background: var(--rz-ants-primary-bg);
      padding: size(12) size(25) size(12) size(16);
      margin-bottom: size(8);
      color: var(--rs-white-color);
      border: size(1) solid var(--rz-ants-color-border);

      &:hover{
        border-color: transparent;
        border: size(1) solid var(--rz-ants-color-border);
      }
      &:focus {
        border: size(1) solid var(--rz-ants-color-border);
        outline: none;
      }
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: var(--rs-white-color);
      background: transparent;
      -webkit-tap-highlight-color: transparent;
      -webkit-box-shadow: 0 0 0 size(30) var(--rz-ants-color-border) inset;
    }
  }
}
</style>

<style lang="scss" scoped>
  .form-group {
    display: flex;
    flex-flow: column;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
  }
</style>

<script>
import { mapState } from 'vuex';
import Config from '@/config';
import { allMetaMixins } from '@/mixin/meta';

import { RequestState, RequestStateStatus } from '@/api/core';

import { User } from '@/store/modules/user';
import { FieldHandler } from '@/core/utils/formModels';

export default {
  mixins: allMetaMixins(),
  title: 'Create your Roanuz account | Roanuz Cricket API',
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      tocLinks: (state) => state.link.cricketFooterTOC,
    }),
    validateSubmit() {
      if ((this.userInfo.email === null) || (this.userInfo.password === null)
        || ((this.userInfo.company_name === null) || (this.userInfo.name === null)
        || (this.userInfo.email === '') || (this.userInfo.password === ''))
        || ((this.userInfo.company_name === '') || (this.userInfo.name === ''))) {
        return true;
      }
      return false;
    },
    loginParams() {
      if (this.$route && this.$route.query && this.$route.query.next_url) {
        return { next_url: this.$route.query.next_url };
      }
      if (this.$route && this.$route.query && this.$route.query.next) {
        return { next: this.$route.query.next };
      }
      return { next: '/user/project/create/' };
    },
  },

  serverPrefetch() {
    return this.fetchData();
  },

  mounted() {
    if (!this.dataLoaded) {
      this.fetchData();
    }
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value.includes(this.$route.meta.routeValue);
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
      }
    }
  },


  watch: {
    $route(to, from) {
      this.isAntsProducts = this.$route.query.show_ants_product
      || new URLSearchParams(this.$route.query.next).get('show_ants_product');
      if (
        to.path !== from.path
        || to.params.sport !== from.params.sport
        || to.params.page !== from.params.page
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },

  data() {
    return {
      loadingState: new RequestState(),
      userInfo: new User(),
      // isTermsChecked: false,
      isTermsChecked: true,
      isCheckedWarning: false,
      signUpInitiated: false,
      errorMessage: null,
      showLoader: false,
      showSignup: true,
      homeUrl: Config.cricketHost,
      antsQueryParams: new URLSearchParams(this.$route.query.next).get('show_ants_product'),
      isAntsProducts: this.$route.query.show_ants_product
       || new URLSearchParams(this.$route.query.next).get('show_ants_product'),
      antsCurrency: this.$route.query.ants_currency
       || new URLSearchParams(this.$route.query.next).get('ants_currency'),
      linkItemList: [
        {
          text: 'API terms of use.',
          url: `${Config.cricketHost}/legal/API-Terms-of-use/`,
        },
        {
          text: 'Terms & Privacy policy.',
          url: `${Config.cricketHost}/legal/Privacy-Policy/`,
        },
        // {
        //   text: 'Refund policy',
        //   url: `${Config.cricketHost}/legal/Refund-Policy/`,
        // },
      ],
      formFields: {
        email: new FieldHandler({
          errorMsg: 'Please enter a valid email.',
          // eslint-disable-next-line max-len
          regExp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        }),
        password: new FieldHandler({
          errorMsg: 'Password must be least 8 characters long with a mix of letters, numbers and symbols.',
          // eslint-disable-next-line max-len
          regExp: /^(?=.*[0-9])(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])[a-zA-Z0-9~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]{8,30}$/,
          minValue: 8,
        }),
        name: new FieldHandler({
          errorMsg: 'Please enter your Full name.',
        }),
        companyName: new FieldHandler({
          errorMsg: 'Please enter a valid company name.',
        }),
        response: new FieldHandler({
          errorMsg: 'Something went wrong!',
        }),
      },
    };
  },
  methods: {
    fetchData() {
      const route = '/user/pages/';
      const routeValue = 'signup';
      return this.$store
        .dispatch('page/fetchActivePage', { route, routeValue })
        .catch((err) => {
          console.error('Error on fetch Active Page', err);
        });
    },
    back() {
      window.history.go(-1);
    },

    processNext(user) {
      const successUrl = decodeURI(this.$route.query.next_url);
      this.$store.commit('boot/updateUser', { user });
      if (this.$route.query.next_url) {
        console.log(`Signup Completed ${user.name}, Moving to ${successUrl}`);
        window.location.href = successUrl;
      } else if (this.$route.query.package) {
        const successRoute = this.$route.query.ants_currency
          // eslint-disable-next-line max-len
          ? decodeURI(`/user/project/create?package=${this.$route.query.package}&show_ants_product=${this.$route.query.show_ants_product}&ants_currency=${this.$route.query.ants_currency}`)
          // eslint-disable-next-line max-len
          : decodeURI(`/user/project/create?package=${this.$route.query.package}&show_ants_product=${this.$route.query.show_ants_product}`);
        console.log(`Signup Completed ${user.name}, Moving to ${successRoute}`);
        this.$router.push(successRoute);
      } else if (this.$route.query.show_ants_product && !this.$route.query.package) {
        const successRoute = decodeURI(Config.antsConsole);
        console.log(`Login Completed ${user.name}, Moving to ${successRoute}`);
        window.location.href = successRoute;
      } else {
        const successRoute = decodeURI(this.$route.query.next || '/user/project/create/');
        console.log(`Signup Completed ${user.name}, Moving to ${successRoute}`);
        this.$router.push(successRoute);
      }
    },

    createAccount() {
      /* eslint-disable operator-linebreak */
      this.signUpInitiated = true;
      this.showSignup = false;
      if (this.validateForm()) {
        this.loadingState.status = RequestStateStatus.Loading;
        User.createUser(this.$apiInstance, this.userInfo)
          .then(({ user, error }) => {
            this.loadingState.status = RequestStateStatus.Loaded;
            this.showSignup = true;
            if (error) {
              console.log('Invalid Signup Request', error);
              this.errorMessage = error.message;
            } else {
              this.processNext(user);
            }
          }).catch((err) => {
            this.errorMessage = 'Internal Error';

            this.loadingState.status = RequestStateStatus.Error;
            this.loadingState.ref = err;
            console.error('Unhandled error during Signup', err);
          });
      } else {
        this.showSignup = true;
      }
    },
    validateForm() {
      let count = 0;
      if (this.userInfo.password === '' || this.userInfo.password === null) {
        this.formFields.password.errorMsg = 'Please provide a password.';
      } else {
        // eslint-disable-next-line max-len
        this.formFields.password.errorMsg = 'Password must be least 8 characters long with a mix of letters, numbers and symbols.';
      }
      if (!this.formFields.name.validateField(this.userInfo.name)) {
        count += 1;
      }
      if (!this.formFields.email.validateField(this.userInfo.email)) {
        count += 1;
      }
      if (!this.formFields.password.validateField(this.userInfo.password)) {
        count += 1;
      }
      if (!this.formFields.companyName.validateField(this.userInfo.company_name)) {
        count += 1;
      }
      if (this.isAntsProducts) {
        this.userInfo.from_ants = true;
      }
      return (count === 0);
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.fetchData();
    },
    buildURL(base, params) {
      const q = Object.keys(params).map((key) => [key, params[key]].map(encodeURIComponent).join('=')).join('&');
      return `${base}?${q}`;
    },
  },
};
</script>
