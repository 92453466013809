<template>
<!-- eslint-disable max-len -->
  <div>
    <template v-if="!$route.query.show_ants_product">
      <svg><use v-bind="{'xlink:href':'#success-illustration'}"></use></svg>
    </template>
    <template v-else>
      <img src="@/assets/ants/Illustration.svg" />
    </template>
    <h2>{{title}}</h2>
    <p>{{description}}</p>
    <div class="btn-wrap">
      <a href="/" v-if="isEnterprise" class="btn focus" target="_blank">Back to Home </a>
      <a v-else-if="$route.query.show_ants_product" :href="antsConsoleLink" class="btn focus" target="_blank" title="Console Dashboard">
        Go to console
      </a>
      <a v-else :href="consoleLink" class="btn focus" target="_blank" title="Console Dashboard">
        Go to console
      </a>
      <p>
        <slot></slot>
      </p>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import { mapState } from 'vuex';
import { buildConsoleProjectLink } from '@/store/modules/user';
import Config from '@/config';

export default {
  name: 'SuccessIllustration',

  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      bootData: (state) => state.boot.instance,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
    }),

    consoleLink() {
      if (this.cartPreviewInput.projectKey) {
        return buildConsoleProjectLink(this.$store.state, this.cartPreviewInput.projectKey);
      }

      return buildConsoleProjectLink(this.$store.state, this.activeProject.key);
    },

    antsConsoleLink() {
      // eslint-disable-next-line max-len
      const link = `${Config.cricketApiBackendHost}/backend/business/login/?next_url=${Config.antsConsole}account/picker`;
      return link;
    },
  },

  props: {
    iconType: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    isEnterprise: {
      type: Boolean,
    },
  },
};
</script>
