<template>
  <div>
    <h2>Load Money</h2>
    <p>Add money to your Roanuz Project.</p>
    <div class="form-body">
      <div
        v-if="cartPreviewInput.canChangeRegion"
        class="form-group currency-selector-div">
        <label class="field-label">Billing Region</label>
        <CurrencyDropdown
          :defaultValue="currency"
          :placeholder="'Preferred Billing Region'"
          @change="updateCurrency($event)"/>
      </div>
      <BillingFormFieldInput
        class="form-group"
        :formField="form.f.prepayAmount"
        :helpText="`Amount should be more than ${formatCurrency(minAmount, cartPreview.currency)}.`"
        :errorMessage="`Amount should be more than ${formatCurrency(minAmount, cartPreview.currency)}.`"
        @change="onAmountChange()"
      />
    </div>
    <div class="btn-wrap">
      <button class="btn focus" @click="nextStep">
        Continue
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';
import { FormHandler } from '@/core/utils/formModels';
import BillingFormFieldInput from '@/components/Payments/BillingFormFieldInput.vue';
import CurrencyDropdown from '@/components/Onboarding/CurrencyDropdown.vue';
import { WizardStep } from '@/store/modules/payments';
import { RequestStateStatus } from '@/api/core';

export default {
  components: {
    BillingFormFieldInput,
    CurrencyDropdown,
  },

  mixins: allMetaMixins(),
  title: 'Enter prepay amount | Roanuz Cricket API',

  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      cartPreview: (state) => state.payments.cartPreview,
      currency: (state) => state.payments.cartPreviewInput.billingCurrency,
      minAmount: (state) => state.payments.cartPreview.prepay_product.amount,
    }),
    selectedBillingMethod: {
      get() {
        return this.$store.state.payments.wizard.selectedBillingMethod;
      },
      set(val) {
        this.updateBillingMethod(val);
      },
    },
  },

  data() {
    const fieldsConfig = [
      {
        key: 'prepayAmount',
        name: 'Amount',
        type: 'number',
        validateOnInput: true,
      },
    ];
    return {
      form: new FormHandler({ fieldsConfig }),
    };
  },

  mounted() {
    this.prepareForm();
  },

  methods: {
    prepareForm() {
      const prepayAmount = this.cartPreviewInput.prepayAmount || this.minAmount;
      const prepayAmountKey = this.cartPreview.prepay_product.product;
      this.$store.commit('payments/updateAddressFromPreview');
      this.form.updateInitValue({ prepayAmount });
      this.form.f.prepayAmount.minValue = this.minAmount - 1;
      this.$store.dispatch('payments/updateCart', { prepayAmount, prepayAmountKey });
    },

    updateMinAmount() {
      this.form.f.prepayAmount.minValue = this.minAmount - 1;
    },

    updateCurrency(currency) {
      this.$store.commit('user/updateActiveCurrency', currency);
      this.$store.dispatch('payments/updateCart', { currency }).then(() => {
        this.updateMinAmount();
      });
    },

    onAmountChange() {
      if (this.form.isValid) {
        this.updateCart();
      } else {
        this.resetCart();
      }
    },

    updateCart() {
      const prepayAmount = this.form.f.prepayAmount.model;
      const prepayAmountKey = this.cartPreview.prepay_product.product;
      this.$store.dispatch('payments/updateCart', { prepayAmount, prepayAmountKey });
    },

    resetCart() {
      const prepayAmount = null;
      const prepayAmountKey = null;
      this.$store.dispatch('payments/updateCart', { prepayAmount, prepayAmountKey });
    },

    nextStep() {
      if (this.form.isValid) {
        this.updateCart();
        this.$router.push({
          name: 'projectWizard',
          params: { projectKey: this.cartPreviewInput.projectKey },
          query: {
            step: 'payment',
            prepay: `${this.cartPreview.prepay_product.product}|${this.form.f.prepayAmount.model}`,
            quantity: parseInt(this.$route.query.quantity, 10) || 1,
            couponKey: (this.$route.query.couponKey !== 'null'
            && this.$route.query.couponKey !== 'undefined') ? this.$route.query.couponKey : null,
            show_ants_product: this.isAntsProducts && true,
          },
        });
        this.$store.commit('payments/updateWizard', { step: WizardStep.Payment });
        this.$store.commit('payments/updateCartSaveState', { status: RequestStateStatus.Loaded });
      } else {
        this.resetCart();
        console.log('Form Validation Errors', this.form.validate());
      }
    },
  },
};
</script>
