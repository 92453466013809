<template>
  <div class="steps-wrapper project-name"  :class="[(isAntsProducts ? 'ants-project-create' : '')]">
    <div class="new-project">
      <button class="change-button" @click="goBack()">
        <svg class="link-img-arrow">
          <use v-bind="{'xlink:href':'#arrow-icon-left'}"></use>
        </svg>
        <p>Back</p>
      </button>
      <h4>STEP 2 OF 3</h4>
      <h2 v-if="isAntsProducts">Give your Account a name</h2>
      <h2 v-else>Give your Project a name</h2>
      <div class="form-body">
        <BillingFormFieldInput
          class="form-group"
          :formField="form.f.project_name"
          @input="onNameInput"
        />
      </div>
    </div>
    <div class="btn-wrap">
      <rz-request-state :state="cartSaveState" />
      <button
        v-if="!$route.query.package"
        class="btn focus"
        @click="moveNextStep()">
        Continue
      </button>
      <button
        v-else
        class="btn focus"
        @click="showNextStep()">
        Continue
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { WizardStep } from '@/store/modules/payments';
import { FormHandler } from '@/core/utils/formModels';
import { Project } from '@/store/modules/project';
import { RequestStateStatus } from '@/api/core';
import BillingFormFieldInput from '@/components/Payments/BillingFormFieldInput.vue';

export default {
  components: {
    BillingFormFieldInput,
  },

  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      projects: (state) => state.boot.instance.projects,
      currency: (state) => state.user.activeCurrency,
      cartPreview: (state) => state.payments.cartPreview,
      cartSaveState: (state) => state.payments.cartSaveState,
      activeUser: (state) => state.boot.instance.user,
    }),
  },

  data() {
    const fieldsConfig = [
      {
        key: 'project_name',
      },
    ];
    return {
      form: new FormHandler({ fieldsConfig }),
      license: (this.$route.query && this.$route.query.license) || null,
      isAntsProducts: this.$route.query.show_ants_product,
    };
  },

  mounted() {
    this.prepareForm();
    if (this.license) {
      if (this.currency === 'inr') {
        this.cartPreviewInput.billingRegion = 'India';
      } else if (this.currency === 'usd') {
        this.cartPreviewInput.billingRegion = 'International';
      } else {
        this.cartPreviewInput.billingRegion = 'Europe';
      }
      if (this.license.includes('annual')) {
        this.cartPreviewInput.selectedLicenseKey = this.license;
        this.cartPreviewInput.prepayAmount = 50;
        this.cartPreviewInput.prepayAmountKey = this.license;
        this.$store.dispatch('payments/fetchCartPreview');
      } else {
        this.cartPreviewInput.selectedLicenseKey = this.license;
        this.$store.dispatch('payments/fetchCartPreview');
      }
      this.$store.commit('payments/updateWizard', { selectedLicense: this.license });
    }
  },


  methods: {
    prepareForm() {
      this.form.updateInitValue({ project_name: this.wizard.givenProjectName || this.activeUser.company_name });
    },

    onNameInput(val) {
      this.$store.commit('payments/updateCartPreviewParam', {
        projectName: val,
      });
    },
    goBack() {
      if (this.license) {
        this.$router.push({
          name: 'planReview',
          query: {
            license: this.license,
            step: 'ChooseLicense',
          },
        });
      } else if (this.projects && this.projects.length > 0 && this.$route.query.package) {
        this.$store.commit('payments/updateWizard', { step: WizardStep.SelectProject });
      } else {
        this.$router.push({
          name: 'planReview',
          query: {
            package: this.$route.query.package,
            step: 'ReviewPlan',
            quantity: parseInt(this.$route.query.quantity, 10) || 1,
            couponKey: (this.$route.query.couponKey !== 'null'
              && this.$route.query.couponKey !== 'undefined') ? this.$route.query.couponKey : null,
          },
        });
      }
    },
    showNextStep() {
      if (this.form.isValid) {
        if (this.cartSaveState.status.isLoading) {
          return;
        }
        const projectJson = {
          name: this.form.f.project_name.model,
          license: 'rs_package',
        };
        this.$store.commit('payments/updateCartSaveState', { status: RequestStateStatus.Loading });
        Project.createProject(this.$apiInstance, projectJson).then((resp) => {
          if (resp) {
            this.$router.push({
              name: 'projectWizard',
              params: { projectKey: resp.key },
              query: {
                step: 'payment',
                prepay: `${this.$route.query.package}|50`,
                package: this.$route.query.package,
                quantity: parseInt(this.$route.query.quantity, 10) || 1,
                couponKey: (this.$route.query.couponKey !== 'null'
                  && this.$route.query.couponKey !== 'undefined') ? this.$route.query.couponKey : null,
                show_ants_product: this.isAntsProducts && true,
                forceLogin: this.$route.query.forceLogin,
              },
            });
            this.$store.commit('payments/updateWizard', { step: WizardStep.Payment });
            this.$store.commit('payments/updateCartSaveState', { status: RequestStateStatus.Loaded });
          }
        }).catch((err) => {
          this.$store.commit('payments/updateCartSaveState', {
            status: RequestStateStatus.Loaded,
            msg: err,
          });
        });
      }
    },
    moveNextStep() {
      if (this.form.isValid) {
        const licenseInfo = this.cartPreview && this.cartPreview.project && this.cartPreview.project.project_license
        && this.cartPreview.project.project_license[0];
        const projectJson = {
          name: this.form.f.project_name.model,
          license: licenseInfo.product,
        };
        if (this.cartSaveState.status.isLoading) {
          return;
        }
        this.$store.commit('payments/updateCartSaveState', { status: RequestStateStatus.Loading });
        Project.createProject(this.$apiInstance, projectJson).then((resp) => {
          if (resp) {
            this.cartPreviewInput.projectKey = resp.key;
            this.$store.dispatch('payments/fetchCartPreview');
            if (licenseInfo.product.includes('annual')) {
              this.$router.push({
                name: 'projectWizard',
                params: { projectKey: resp.key },
                query: {
                  step: 'payment',
                  prepay: `${licenseInfo.product}|50`,
                  quantity: parseInt(this.$route.query.quantity, 10) || 1,
                  couponKey: (this.$route.query.couponKey !== 'null'
                    && this.$route.query.couponKey !== 'undefined') ? this.$route.query.couponKey : null,
                  show_ants_product: this.isAntsProducts && true,
                },
              });
              this.$route.query.license = licenseInfo.product;
              this.$store.commit('payments/updateWizard', { step: WizardStep.Payment });
            } else {
              this.$router.push({
                name: 'projectWizard',
                params: { projectKey: resp.key },
                query: {
                  step: 'billing',
                  quantity: parseInt(this.$route.query.quantity, 10) || 1,
                  couponKey: (this.$route.query.couponKey !== 'null'
                    && this.$route.query.couponKey !== 'undefined') ? this.$route.query.couponKey : null,
                  show_ants_product: this.isAntsProducts && true,
                },
              });
              this.$route.query.license = licenseInfo.product;
              this.$store.commit('payments/updateWizard', { step: WizardStep.Billing });
            }
            this.$store.commit('payments/updateCartSaveState', { status: RequestStateStatus.Loaded });
          }
        }).catch((err) => {
          this.$store.commit('payments/updateCartSaveState', {
            status: RequestStateStatus.Loaded,
            msg: err,
          });
        });
      }
    },
  },
};
</script>
<style lang="scss">
.project-name {
  .page-request-state{
    width: 80%;
    .loading-msg {
      text-align: left;
      font-size: size(15);
      line-height: size(20);
      color: var(--rs-black-color);
      padding-bottom: size(20)
    }
  }
  .form-body {
    width: auto;
    @media screen and (min-width: $breakpoint-md) {
      width: size(420);
    }
    input {
      width: auto;
      height: size(50);
      @media screen and (min-width: $breakpoint-md) {
        width: size(420);
      }
    }
  }
}
.ants-project-create {
  .page-request-state {
    .loading-msg {
      color: var(--rs-white-color);
    }
   }
}
</style>
<style lang="scss" scoped>
.steps-wrapper {
  padding-left: 0;
  h4 {
    font-size: size(12);
    line-height: size(17);
    font-weight: 500;
    color: var(--rs-black-color);
    margin-bottom: size(10)
  }
  .change-button {
    visibility: hidden;
  }
}
.ants-project-create {
  font-family: var(--rz-ants-font);
  h2, h4 {
    color: var(--rs-white-color);
   }
}
</style>
