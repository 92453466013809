<template>
  <div class="steps-wrapper">
    <div class="new-project choose-license">
      <button class="change-button" @click="goBack()">
        <svg class="link-img-arrow">
          <use v-bind="{'xlink:href':'#arrow-icon-left'}"></use>
        </svg>
        <p>Back</p>
      </button>
      <h4>STEP 1 OF 3</h4>
      <!-- <h2>Choose a plan that’s right for you</h2> -->
      <div class="license-wrapper">
        <template v-for="license in licenseNames">
          <template v-if="licenseInfo && licenseInfo[license]">
            <div
              v-if="((licenseInfo[license].monthly
                && licenseInfo[license].monthly.product !== 'rs_package') ||
              (licenseInfo[license].annual
                && licenseInfo[license].annual.product !== 'rs_package'))"
              :key="(licenseInfo[license].monthly.product || licenseInfo[license].annual.product)"
              class="license"
              :class="(licenseInfo[license].monthly.product || licenseInfo[license].annual.product)"
            >
              <div class="image-block" :class="licenseInfo[license].monthly.product">
                <!-- <template v-if="licenseInfo[license].monthly.product === 'rs_paygo_standard'">
                  <img src="@/assets/standard-img.png" />
                </template>
                <template v-else-if="licenseInfo[license].monthly.product === 'rs_paygo_premium'">
                  <img src="@/assets/premium-img.png" />
                </template> -->
                <template v-if="licenseInfo[license].monthly.product === 'rs_paygo_enterprise_a101'">
                  <img src="@/assets/enterprise-img.png" />
                </template>
              </div>
              <div class="content-block">
                <h3>Pay as you go</h3>
                <template v-if="licenseInfo[license].monthly.product === 'rs_paygo_standard'">
                  <p>The quickest and easiest way to try Roanuz Cricket API</p>
                </template>
                <template v-else-if="licenseInfo[license].monthly.product === 'rs_paygo_premium'">
                  <p>Many Free APIs, Volume Discounts and better access control</p>
                </template>
                <template v-else-if="licenseInfo[license].monthly.product === 'rs_paygo_enterprise_a101'">
                  <p>Even more free APIs, better Volume Discounts and historical access</p>
                </template>
                <p class="standard-price" v-if="licenseInfo[license].monthly
                  && (licenseInfo[license].monthly.product === 'rs_paygo_standard')">
                  {{formatCurrency(licenseInfo[license].monthly.price.unit_amount[currency], currency)}}
                </p>
                <template v-else>
                  <!-- <div class="form-group custom-radio" v-if="licenseInfo[license].monthly">
                    <input
                      type="radio"
                      v-model="selectedLicense"
                      :value="licenseInfo[license].monthly.product"
                      @change="onLicenseChange(license)"
                    />
                    <span class="checkmark"></span>
                    <h3>
                      <span>Monthly</span>
                      <strong>
                        {{formatCurrency(licenseInfo[license].monthly.price.unit_amount[currency], currency)}}
                      </strong></h3>
                  </div> -->
                  <h3>
                    <span>Annually</span>
                    <strong>
                      {{formatCurrency(licenseInfo[license].annual.price.unit_amount[currency], currency)}}
                    </strong>
                    <!-- <p class="saved-price">
                      Save
                        {{formatCurrency(
                        ((licenseInfo[license].monthly.price.unit_amount[currency] * 12)
                        - (licenseInfo[license].annual.price.unit_amount[currency]))
                      , currency)}}
                    </p> -->
                  </h3>
                </template>
                <div class="button-wrapper">
                  <router-link class="license-info" :to="{name: 'rsPricing'}" target="_blank">
                    Plus API Usage charges
                  </router-link>
                  <div v-if="licenseInfo[license].monthly.product !== 'rs_paygo_standard'">
                    <rz-request-state :state="createProjectState" />
                    <div
                      class="form-errors error-field"
                      :class="errorMessage &&
                        (licenseInfo[license].monthly.common_name === notChosenLicense && !selectedLicense)
                       ? 'show-error': ''"
                    >
                      <p class="error-msg">
                        {{errorMessage}}
                      </p>
                    </div>
                  </div>
                  <button v-if="licenseInfo && licenseInfo[license] && licenseInfo[license].monthly"
                    @click="moveNextStep(license)">Select</button>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
      <!-- <div class="other-products">
        <router-link
          :to="{ name: 'rsPricing' }"
          target="_blank"
        >
          <img src="@/assets/compare.svg" alt="External icon">
          Compare plans
        </router-link>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';
import { FormHandler } from '@/core/utils/formModels';
import { WizardStep } from '@/store/modules/payments';
import { licenseTypes } from '@/store/modules/paygo';

export default {

  mixins: allMetaMixins(),
  title: 'Select a Suitable Plan | Roanuz Cricket API',

  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      createProjectState: (state) => state.payments.createProjectState,
      currency: (state) => state.user.activeCurrency,
      licenseInfo: (state) => state.payments.wizard.licenses,
      cartPreview: (state) => state.payments.cartPreview,
    }),
    selectedLicense: {
      get() {
        return this.$store.state.payments.wizard.selectedLicense;
      },
      set(val) {
        this.updateLicense(val);
      },
    },
  },

  data() {
    const fieldsConfig = [
      {
        key: 'project_name',
        name: 'Project Name',
      },
    ];
    return {
      form: new FormHandler({ fieldsConfig }),
      errorMessage: '',
      licenseTypes,
      license: null,
      notChosenLicense: null,
      licenseNames: ['enterprise', 'package'],
    };
  },

  mounted() {
    this.initializeLicense();
  },


  methods: {
    updateCurrency(currency) {
      this.$store.commit('user/updateActiveCurrency', currency);
    },

    updateLicense(key) {
      this.license = key;
      this.$store.commit('payments/updateWizard', { selectedLicense: key });
    },

    onLicenseChange(license) {
      this.$store.commit('payments/updateCartPreviewParam', {
        selectedLicense: license,
      });
    },

    validate(key) {
      if (!this.wizard.selectedLicense) {
        this.errorMessage = 'Please select a billing cycle';
        this.notChosenLicense = key;
        return false;
      }

      return true;
    },

    goBack() {
      this.$store.commit('payments/updateWizard', { step: WizardStep.SelectPlan });
    },

    moveNextStep(key) {
      this.updateLicense(this.licenseTypes.enterprisea101);
      this.$store.commit('payments/updateCartPreviewParam', {
        selectedLicense: key,
      });
      if (this.cartPreview && this.cartPreview.project && this.cartPreview.project.project_license
         && this.cartPreview.project.project_license[0]) {
        this.$store.commit('payments/updateWizard', { step: WizardStep.ProjectName });
      } else {
        this.$store.commit('payments/updateCartPreviewParam', {
          selectedLicense: 'standard',
        });
        this.license = 'rs_paygo_standard';
        this.$store.commit('payments/updateWizard', { step: WizardStep.ProjectName });
      }
      this.$router.push({
        name: 'projectCreation',
        query: {
          license: key === 'enterprise' ? 'rs_paygo_enterprise_a101_annual' : this.license,
        },
      });
    },

    initializeLicense() {
      if (this.$route && this.$route.query && this.$route.query.license) {
        if (this.$route.query.license === 'standard') {
          this.updateLicense(this.licenseTypes.standard);
        } else if (this.$route.query.license === 'enterprisea101') {
          this.updateLicense(this.licenseTypes.enterprisea101);
        } else if (this.$route.query.license === 'premium') {
          this.updateLicense(this.licenseTypes.premium);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.choose-license {
  &.new-project {
    h2 {
      margin-bottom: size(20);
    }
    h4 {
      font-size: size(12);
      line-height: size(17);
      font-weight: 500;
      color: var(--rs-black-color);
      margin-bottom: size(10)
    }
     h3 {
      margin-bottom: size(10);
      &:last-child {
        margin-bottom: 0;
      }
      span {
        display: inline-block;
        font-size: size(12);
        line-height: size(17);
        font-weight: 500;
        color: var(--ca-title-color);
      }
      strong {
        font-size: size(15);
        line-height: size(21);
        font-weight: bold;
        float: right;
      }
      .saved-price {
        font-size: size(12);
        line-height: size(17);
        margin-bottom: 0;
        color: #00A656;
        margin-top: size(2);
        padding-bottom: 0!important;
      }
    }
    .form-group {
      &.custom-radio {
        display: block;
        width: 100%;
        input {
          left: 0;
          height: size(40);
          max-height: size(50);
        }
        .checkmark {
          left: size(8);
          top: size(12);
        }
        h3 {
          padding: size(10) size(8);
          background: #F3F3F3;
          border-radius: size(6);
          margin-bottom: size(10);
          &:last-child {
            margin-bottom: 0;
          }
          span {
            display: inline-block;
            font-size: size(12);
            line-height: size(17);
            font-weight: 500;
            color: var(--ca-title-color);
            padding-left: size(26);
          }
          strong {
            font-size: size(15);
            line-height: size(21);
            font-weight: bold;
            float: right;
          }
          .saved-price {
            font-size: size(12);
            line-height: size(17);
            margin-bottom: 0;
            color: #00A656;
            margin-top: size(2);
            padding-bottom: 0!important;
            padding-left: size(26);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.steps-wrapper {
  .variations-types-wrap {
    .variation {
      &.rs_paygo_enterprise_c1x {
        display: none;
      }
    }
  }
}
.choose-license {
  &.new-project {
    h2 {
      margin-bottom: size(20);
    }
  }
  .license-wrapper {
    margin-top: size(100);
    margin-bottom: size(45);
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      flex-direction: row;
    }
    .license {
      width: size(250);
      margin-right: size(18);
      background: var(--rs-white-color);
      border-radius: size(6);
      margin-bottom: size(50);
      box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
        0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
      @media screen and (min-width: $breakpoint-md) {
        margin-bottom: 0;
      }
      .image-block {
        height: size(80);
        border-radius: size(6);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
        @media screen and (min-width: $breakpoint-md) {
          height: size(100);
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(120);
        }
        &.rs_paygo_standard {
          background: #ED90B2;
        }
        &.rs_paygo_premium {
          background: #97E6AB;
        }
        &.rs_paygo_enterprise_a101 {
          background: #A695FF;
        }
        img {
          position: absolute;
          display: inline-block;
          height: size(120);
          top: size(-40);
          @media screen and (min-width: $breakpoint-md) {
            height: size(120);
            top: size(-40);
          }
          @media screen and (min-width: $breakpoint-lg) {
            height: size(180);
            top: size(-60);
          }
        }
      }
      .content-block {
        padding: size(15);
        position: relative;
        min-height: size(320);
        & > h3 {
          font-size: size(15);
          line-height: size(21);
          color: var(--ca-title-color);
          font-weight: 700;
          padding-bottom: size(6);
        }
        p {
          font-size: size(12);
          line-height: size(17);
          padding-bottom: size(20);
        }
        .standard-price {
          font-size: size(15);
          line-height: size(21);
          font-weight: bold;
        }
        .button-wrapper {
          position: absolute;
          bottom: size(15);
          left: size(15);
          right: size(15);
          & > a {
            display: inline-block;
            color: var(--rz-link-color);
            font-size: size(12);
            line-height: size(17);
            font-weight: 500;
            margin-bottom: size(10);
            text-align: left;
            margin-top: size(20);
          }
          .error-field {
            height: size(30);
            .error-msg {
              padding: size(5) 0;
              margin: 0;
            }
            visibility: hidden;
            &.show-error {
              visibility: visible;
            }
          }
        }
        button {
          display: block;
          text-align: center;
          background-color: var(--rz-link-color);
          color: var(--rs-white-color);
          font-size: size(12);
          line-height: size(17);
          width: 100%;
          padding: size(10);
          border-radius: size(5);
          cursor: pointer;
        }
      }
      &.rs_paygo_standard {
        .content-block {
          .button-wrapper {
            & > a {
              margin-bottom: size(40);
            }
          }
        }
      }
    }
  }
}
.other-products {
  padding-bottom: size(20);
  a {
    font-size: size(12);
    line-height: size(17);
    font-weight: 500;
    color: var(--on-boarding-btn-bg);
    margin-left: size(50);
    &:hover {
      opacity: 0.8;
    }
    &:first-child {
      margin-left: 0;
    }
    img {
      display: inline-block;
      vertical-align: top;
      width: size(20);
      height: size(20);
      margin-right: size(8);
    }
  }
}
</style>
