<template>
  <div class="docs-wrap on-boarding-wrap">
    <OnBoardingLoader
      v-if="cartPreviewState.status.isError"
      :isErrorState="true"
      :title="'Oops! Something went wrong'"
      :description="'Make sure you got the correct url and try again after sometime.'"
    />
    <OnBoardingLoader
      v-else-if="(!cartPreviewLoaded)"
      :title="'Processing Your Request!'"
      :description="'Give us a minute, as we are processing your request.'"
    />
    <div class="page-container" v-else>
      <div class="split-cols">
        <div class="column-1">
          <div class="steps-wrapper">
            <CommittedUseStep v-if="wizard.step.isCommittedUse" />
            <BillingMethodStep v-if="wizard.step.isBillingMethod" />
            <PaymentStep
              v-if="wizard.step.isBilling"
              title="Setup Billing"
            />
            <PaymentStep
              v-if="wizard.step.isPayment"
              title="Make Payment"
            />
            <PrepayStep v-if="wizard.step.isPrepay" />
            <SuccessStep v-if="wizard.step.isCompleted" />
          </div>
        </div>

        <div
          v-if="!wizard.step.isCompleted"
          class="column-2 progress-bar"
        >
          <CartPreview />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .page-container {
    max-width: 100%;
    margin: auto;
    padding-bottom: size(50);
    @media screen and (min-width: $breakpoint-md) {
      max-width: var(--content-max-width);
    }
  }
  .docs-wrap {
    padding-top: var(--master-menu-height);
    margin-top: 0
  }
</style>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';
import '@/assets/styles/views/onboarding.scss';
import OnBoardingLoader from '@/components/Onboarding/OnBoardingLoader.vue';

import CommittedUseStep from '@/components/Payments/CommittedUseStep.vue';
import PaymentStep from '@/components/Payments/PaymentStep.vue';
import BillingMethodStep from '@/components/Payments/BillingMethodStep.vue';
import PrepayStep from '@/components/Payments/PrepayStep.vue';
import SuccessStep from '@/components/Payments/SuccessStep.vue';

import CartPreview from '@/components/Payments/CartPreview.vue';

export default {
  components: {
    OnBoardingLoader,
    CommittedUseStep,
    PaymentStep,
    BillingMethodStep,
    PrepayStep,
    SuccessStep,
    CartPreview,
  },
  mixins: allMetaMixins(),

  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      cartPreviewLoaded: (state) => state.payments.cartPreview && state.payments.cartPreview.project.key,
      cartPreview: (state) => state.payments.cartPreview,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      cartPreviewState: (state) => state.payments.cartPreviewState,
      cartSaveState: (state) => state.payments.cartSaveState,
      userCurrency: (state) => state.user.activeCurrency,
      requestNextStep: (state) => state.payments.wizard.requestNextStep,
    }),
  },

  data() {
    return {
      isAntsProducts: this.$route.query.show_ants_product,
    };
  },

  watch: {
    requestNextStep(val) {
      if (val && val.value !== this.wizard.step) {
        this.$router.push({
          query: {
            step: val.value,
            show_ants_product: this.$route.query.show_ants_product && true,
            forceLogin: this.$route.query.forceLogin,
          },
        });
      }
    },
    '$route.query.step': function nextStep() {
      const step = this.$route.query.step || this.wizard.firstStep.value;
      console.log(`Setting new step ${step}`);
      this.$store.commit('payments/updateWizard', { stepRaw: step });
    },

    isAntsProducts() {
      document.title = this.isAntsProducts ? 'Setup Project | Roanuz Ants' : 'Setup Project | Roanuz Cricket API';
    },
  },

  // serverPrefetch() {
  //   return this.fetchData();
  // },

  mounted() {
    if (!this.cartPreviewLoaded) {
      this.fetchData();
    }

    document.title = this.isAntsProducts ? 'Setup Project | Roanuz Ants' : 'Setup Project | Roanuz Cricket API';
  },


  methods: {
    fetchData() {
      const { projectKey } = this.$route.params;
      const step = this.$route.query.step || this.wizard.firstStep.value;
      let [prepayAmountKey, prepayAmount] = [null, null];
      const { cukey, prepay, invoice } = this.$route.query;
      let currency = this.userCurrency;
      console.log('User Currency', currency);

      if (prepay) {
        [prepayAmountKey, prepayAmount] = prepay.split('|');
      }

      const givenCurrency = this.$route.query.currency;
      if (givenCurrency && givenCurrency !== currency) {
        currency = givenCurrency;
        this.$store.commit('user/updateActiveCurrency', currency);
      }

      this.$store.commit('payments/updateWizard', { stepRaw: step });

      const loadCommitedUseItems = this.wizard.step.isCommittedUse;
      this.$store.dispatch('payments/updateCart', {
        projectKey,
        currency,
        committedUseProduct: cukey,
        loadCommitedUseItems,
        prepayAmountKey,
        prepayAmount,
        invoiceKey: invoice,
        ...(this.$route.query.renewal_key && {
          projectProductKey: this.$route.query.renewal_key,
        }),
      });
    },

    updateCart() {
      this.$store.dispatch('payments/fetchCartPreview');
    },
  },
};
</script>
