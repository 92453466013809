<template>
  <div class="on-boarding-wrap">
    <OnBoardingLoader
      v-if="projectSetupState.status.isError"
      :isErrorState="true"
      :title="'Oops! Something went wrong'"
      :description="'Make sure you got the correct url and try again after sometime.'"
    />
    <OnBoardingLoader
      v-else-if="projectSetupState.status.isLoading || projectSetupState.status.isNotInit"
      :title="'Processing Your Request!'"
      :description="'Give us a minute, as we are processing your request.'"
    />
    <OnBoardingLoader
      v-else-if="createProjectState.status.isLoading"
      :title="'Creating your Project!'"
      :description="'Give us a minute, as we are processing your request.'"
    />
    <div
      class="page-container"
      v-else
    >
      <div class="split-cols">
        <div class="column-1">
          <SelectPlan v-if="wizard.step.isSelectPlan" />
          <ReviewPlan v-if="wizard.step.isReviewPlan" />
          <SelectLicenseStep v-if="wizard.step.isChooseLicense" />
        </div>
        <div
          v-if="!(wizard.step.isChooseLicense || wizard.step.isSelectPlan)"
          class="column-2 progress-bar"
        >
          <CartPreview />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.on-boarding-wrap {
  padding-top: size(65);
  margin-top: 0;
  margin: 0 size(30);
  @media screen and (min-width: $breakpoint-lg) {
    margin: 0 0 auto;
  }
  .page-container {
    max-width: var(--content-max-width);
    margin: auto;
  }

  .page-request-state {
    .error-msg {
      display: block;
    }
  }
}
</style>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';
import '@/assets/styles/views/onboarding.scss';
import { WizardStep } from '@/store/modules/payments';
import OnBoardingLoader from '@/components/Onboarding/OnBoardingLoader.vue';

import SelectLicenseStep from '@/components/Payments/SelectLicenseStep.vue';
import SelectPlan from '@/components/Payments/SelectPlan.vue';
import ReviewPlan from '@/components/Payments/ReviewPlan.vue';


import CartPreview from '@/components/Payments/CartPreview.vue';

export default {
  components: {
    SelectLicenseStep,
    SelectPlan,
    CartPreview,
    ReviewPlan,
    OnBoardingLoader,
  },

  mixins: allMetaMixins(),
  title: 'Create Project | Roanuz Cricket API',

  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      createProjectState: (state) => state.payments.createProjectState,
      projectSetupState: (state) => state.payments.projectSetupState,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
    }),
  },


  mounted() {
    if (!this.wizard.licenses.length) {
      this.fetchData();
    }
  },

  methods: {
    fetchData() {
      console.log('Setting up Project Create');
      let currency = this.userCurrency;

      const givenCurrency = this.$route.query.currency;
      if (givenCurrency && givenCurrency !== currency) {
        currency = givenCurrency;
        this.$store.commit('user/updateActiveCurrency', currency);
      }

      const forwardParamKeys = ['cukey', 'prepay'];
      const paymentForwardParams = {};

      forwardParamKeys.forEach((key) => {
        const val = this.$route.query[key];
        if (val) {
          paymentForwardParams[key] = val;
        }
      });
      this.$store.commit('payments/updateWizard', { paymentForwardParams });
      this.$store.dispatch('payments/prepareProjectSetup').then(() => {
        if (this.$route.query.step === 'ChooseLicense') {
          this.$store.commit('payments/updateWizard', { step: WizardStep.ChooseLicense });
        } else if (this.$route.query.step === 'ReviewPlan') {
          this.$store.commit('payments/updateWizard', { step: WizardStep.ReviewPlan });
        } else {
          this.$store.commit('payments/updateWizard', { step: WizardStep.SelectPlan });
        }
      });
    },
  },
};
</script>
