<template>
  <div class="dropdown">
    <transition name="slide">
      <select
        class="dropdown"
        @change="changeCurrency"
        :disabled="disabled"
      >
        <template
          v-for="(option, index) in currencies._values"
          >
          <option class="dropdown__list"
            :value= option.value
            :key="index"
            v-if="option.value"
            :selected="(defaultValue === option.value)"
            :class="{'selected': (defaultValue === option.value)}"
          >
            {{option.symbol}} {{option.label}}
          </option>
        </template>
     </select>
    </transition>
  </div>
</template>
<style lang="scss">
.dropdown {
  position: relative;
  select {
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    cursor: pointer;
    background: url('../../assets/dropdown.svg') no-repeat;
    background-size: size(8) size(5);
    background-position: right size(10) bottom size(18);
  }
  &__list__default {
    padding: size(5);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &-value {
      font-size: size(20);
      line-height: size(24);
      color: var(--regular-text);
      font-family: var(--regular-font);
      span {
        color: var(--ca-title-color);
      }
    }
  }
  &__list {
    &__default {
      &.remove-border {
        border-bottom: none!important;
      }
    }
    &__wrap {
      position: absolute;
      background: #fff;
      right: 0;
      left: 0;
      z-index: 99;
      top: size(40);
      transform-origin: top;
      transition: height .3s linear;
      border: size(1) solid #c4c4c4;
      border-top: none;
      padding: 0 size(10) size(10);
      &.add-height {
        height: size(110);
        border-bottom-left-radius: size(3);
        border-bottom-right-radius: size(3);
      }
    }
    background-color: #fff;
    padding: size(5) size(15);
    font-size: size(20);
    line-height: size(24);
    color: var(--regular-text);
    padding-left: 0!important;
    font-family: var(--regular-font);
    &:first-child {
      border-top: size(1) solid #c4c4c4;
      padding-top: size(10);
    }
    cursor: pointer;
    &__arrow-icon {
      &.rotate {
        transform: rotate(180deg);
      }
      transition: transform .25s ease-in-out;
    }
    &.selected {
      color: var(--on-boarding-btn-bg);
    }
    &:hover, &:focus {
      color: var(--on-boarding-btn-bg);
    }
  }
  .dropdown__inner__wrapper {
    height: 100%;
    overflow: hidden;
  }
  .slide-enter, .slide-leave-to{
    height: 0;
    transition: height 0.3s linear;
  }
}
</style>
<script>
import { Currencies } from '@/store/modules/paygo';

/* eslint-disable no-underscore-dangle */
export default {
  name: 'CurrencyDropdown',
  props: {
    defaultValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDropdown: false,
      currencies: Currencies.toJSON(),
    };
  },
  directives: {
    'click-outside': {
      bind: (el, binding, vNode) => {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '
          ${binding.expression}' is not a function, but has to be`;
          if (compName) { warn += `Found in component '${compName}'`; }
          console.warn(warn);
        }
        // Define Handler and cache it on the element
        const { bubble } = binding.modifiers;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;

        // add Event Listeners
        document.addEventListener('click', handler);
      },
      unbind: (el) => {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
  methods: {
    changeCurrency(currency) {
      const currencyVal = currency.target.value;
      this.$emit('change', currencyVal);
      this.$store.commit('project/updateCurrency', currencyVal);
      this.$emit('dropdown-value', currencyVal);
    },
    closeDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>
