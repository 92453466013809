<template>
  <div
    :class="{'error-field': formField.showError}"
  >
    <label class="field-label">{{formField.name}}</label>
    <div class="dropdown">
      <select
        class="dropdown"
        :class="{'disabled': formField.disabled}"
        :disabled="formField.disabled"
        v-model="formField.model"
        :name="formField.formName"
        :autocomplete='formField.autocompleteName'
        @change="$emit('change', $event.target.value)"
      >
        <option
          v-for="option in options"
          :key="option.key"
          :value="option.key"
          class="dropdown__list"
          :class="{selected: formField.model == option.key}"
        >{{option.name}}
        </option>
      </select>
    </div>
    <p v-if="formField.showError" class="error-msg">
      {{formField.message}}
    </p>
  </div>
</template>
<style lang="scss" scoped>
.error-msg {
  width: size(275);
}
.dropdown {
  position: relative;

  select {
    max-width: size(1000);
  }

  &.disable {
    color: grey;
  }

  &__list {
    &__wrap {
      position: absolute;
      background: #fff;
      left: 0;
      min-width: size(100);
      max-height: size(450);
      overflow-y: auto;
      padding-bottom: size(10);
      z-index: 99;
      transform-origin: top;
      transition: tr .4s ease-in-out;
      box-shadow: 0 size(1) size(2) 0 var(--gray-line);
      &.add-height {
        height: size(300)!important;
      }
    }
    background-color: #fff;
    padding: size(4) size(15);
    font-size: size(20);
    line-height: size(24);
    color: var(--regular-text);
    font-family: var(--regular-font);
    cursor: pointer;
    &__arrow-icon {
      &.rotate {
        transform: rotate(180deg);
      }
      transition: transform .3s ease-in-out;
    }
    &.selected {
      color: var(--on-boarding-btn-bg);
    }
    &:hover, &:focus {
      color: var(--on-boarding-btn-bg);
    }
  }
}
</style>
<script>
export default {
  props: {
    formField: Object,
    options: Array,
  },
};
</script>
