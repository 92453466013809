<template>
  <div class="payment-field-outer">
    <template>
      <label
        class="payment-field"
        :class="{'active': isSelected}"
      >
        <h3 class="type-heading">{{name}}</h3>
        <h4 class="type-sub-heading">
          <slot name="title"></slot>
        </h4>
        <input
          type="radio"
          :value="method.key"
          :checked="isSelected"
          @change="$emit('change', method)"
        >
        <span class="checkmark"></span>
        <slot name="icon">
          <div class="payment-logo">
            <svg
              v-if="!isAntsProducts"
              :class="[method.payment_gateway, method.payment_medium]"
            >
              <use v-bind="{'xlink:href':'#' + iconClass}"></use>
            </svg>
            <template v-else>
              <img src="@/assets/ants/paypal.svg" class="paypal-logo"
                v-if="method.payment_medium === 'invoice' && method.payment_gateway ==='paypal'" alt="Paypal Logo">
              <img src="@/assets/ants/invoice.svg" class="invoice-logo"
                v-if="method.payment_medium === 'invoice' && method.payment_gateway !=='paypal'" alt="Invoice Logo">
              <img src="@/assets/ants/razorpay.svg" v-if="method.payment_medium === 'invoice'
              && method.payment_gateway ==='gateway'" alt="Razorpay Logo">
            </template>
          </div>
        </slot>
      </label>
      <h5 class="payment-info" v-show="isSelected">
        <svg><use v-bind="{'xlink:href':'#help-icon'}"></use></svg>
        <slot name="desc"></slot>
        <div v-if="method.payment_gateway === 'stripe'" class="save-card">
          <input type="checkbox" @change="$emit('saveCard', $event)" >
          <label>Save this card</label>
        </div>
      </h5>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    isSelected: Boolean,
    name: String,
    iconClass: String,
    method: Object,
    isAntsProducts: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
    }),
  },
};
</script>
<style lang="scss" scoped>
.save-card {
  font-size: size(14);
  line-height: size(20);
  display: flex;
  gap: size(10);
  padding-top: size(10);
  color: var(--rs-black-color);
  font-weight: 500;
}

.invoice-logo {
  max-width: size(50);
  max-height: size(25);
}
.paypal-logo {
  max-width: size(60);
}
</style>
