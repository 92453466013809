<template>
  <div>
    <div
      class="success-notifier"
      v-if="wizard.result.kind.isGetInTouch"
    >
      <SuccessIllustration
        :iconType="'enterprise'"
        :title="'Thank you for choosing Enterprise C1X!'"
        :isEnterprise="true"
        :description="'Our Sales Executive will contact you shortly! But we are open to questions!'"
      />
    </div>
    <div
      class="success-notifier virtual-sports"
      v-if="wizard.result.kind.isAskedForFree"
    >
      <SuccessIllustration
        :iconType="'virtual'"
        :title="'Yay, you can now access all the endpoints using Virtual Sports!'"
      />
    </div>
    <div
      class="success-notifier"
      v-if="wizard.result.kind.isCardVerified"
    >
      <SuccessIllustration
        :iconType="'virtual'"
        :title="'Thank you. Your card is verified!'"
        :description="'Your card details have been verified successfully! You can now access live data.'"
      />
    </div>
    <div
      class="success-notifier"
      v-if="wizard.result.kind.isVerificationRequired || wizard.result.kind.isNothing
      || wizard.result.kind.isPaymentError"
    >
      <SuccessIllustration
        :iconType="'virtual'"
        :title="'Thank you. Your account will be verified!'"
        :description="'Our team will verify your account and enable access to live data.'"
      />
    </div>
    <div
      class="success-notifier"
      v-if="wizard.result.kind.isInvoiceCreated"
    >
      <SuccessIllustration
        :iconType="'virtual'"
        :title="'Thank you. Invoice created!'"
        :description="messages.invoiceCreated.msg"
      />
    </div>
    <div
      class="success-notifier"
      v-if="wizard.result.kind.isPaymentReceived"
    >
      <SuccessIllustration
        :iconType="'virtual'"
        :title="'Thanks. Payment Successful!'"
        :description="'We have received your payment. You can now head over to the console and access live data.'"
      >
        <a
          v-if="wizard.result.invoiceKey && !isAntsProducts"
          :href="invoiceUrl"
          class="btn link"
          target="_blank"
          title="Console Dashboard"
        >
          View receipt
        </a>
      </SuccessIllustration>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';
import SuccessIllustration from '@/components/Onboarding/SuccessIllustration.vue';
import { buildConsoleProjectLink } from '@/store/modules/user';

export default {
  components: {
    SuccessIllustration,
  },
  mixins: allMetaMixins(),
  title: 'Access Virtual Sports | Roanuz Cricket API',

  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
    }),

    invoiceUrl() {
      console.log('a', this.wizard.result.kind);
      return buildConsoleProjectLink(
        this.$store.state,
        this.cartPreviewInput.projectKey,
        `invoice/${this.wizard.result.invoiceKey}/`,
      );
    },
  },
  mounted() {
    document.title = this.isAntsProducts
      ? 'Access Virtual Sports | Roanuz Ants' : 'Access Virtual Sports | Roanuz Cricket API';
  },

  watch: {
    isAntsProducts() {
      document.title = this.isAntsProducts
        ? 'Access Virtual Sports | Roanuz Ants' : 'Access Virtual Sports | Roanuz Cricket API';
    },
  },
  data() {
    const messages = {
      invoiceCreated: {
        msg: 'Please transfer the invoiced amount to our bank account.'
        + ' Please get our account details from invoice.',
      },
    };
    return {
      messages,
      isAntsProducts: this.$route.query.show_ants_product,
    };
  },
};
</script>
