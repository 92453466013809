<template>
  <div
  :class="['sign-up login' ,(isAntsProducts ?'ants-product-login' : '' )]"   v-if="dataLoaded">
    <div class="flex" v-on:keyup.enter="login">
      <div class="flex-col-right" :class="[signUpInitiated ? 'initiated' : null]">
        <div class="form-wrapper">
          <h1 class="signup-text">Sign in  to your account</h1>
          <p class="login-info">
            Don't have an account?
            <router-link class="login-info-link"
              v-if="isAntsProducts && !antsQueryParams"
              :to="{ name: 'signUp', query:{...$route.query} }">
                Sign up
            </router-link>
            <router-link class="login-info-link"
              v-else
              :to="{ name: 'signUp', query: loginParams  }">
                Sign up
            </router-link>
          </p>
          <div>
            <div :class="['form-group', (formFields.email.showError ? 'error-field' : ''),
             (isAntsProducts ? 'ants-input' : '')]">
              <label class="field-label" for="workemail">Email</label>
              <input
                :class="[!formFields.email.showError ? 'tick-icon' : null]"
                type="email"
                v-model="userInfo.email"
                name="workemail"
                id="workemail"
                placeholder=""
                autocomplete="off"
                autofocus
              />
              <p v-if="formFields.email.showError" class="error-msg">{{formFields.email.errorMsg}}</p>
            </div>
            <div :class="['form-group password-div', (formFields.password.showError ? 'error-field' : '')]">
              <label class="field-label password" for="password">Password</label>
              <router-link class="forget-pass-btn" v-if="isAntsProducts"
                :to="{ name: 'forgotPassword' , query:{...queryParams}}">
                  Forgot password?
              </router-link>
              <router-link class="forget-pass-btn" v-else
                :to="{ name: 'forgotPassword'} ">
                  Forgot password?
              </router-link>
              <input
                :class="[!formFields.password.showError ? 'tick-icon' : null]"
                :type="[showPassCode ? 'text' : 'password']"
                v-model="userInfo.password"
                name="password"
                id="password"
                placeholder=" "
                autocomplete="on"
              />
              <p v-if="formFields.password.showError" class="error-msg">{{formFields.password.errorMsg}}</p>
            </div>
            <div :class="['form-group', (formFields.response.showError ? 'error-field' : '')]">
              <p v-if="formFields.response.showError" class="error-msg">{{formFields.response.errorMsg}}</p>
            </div>
            <button :disabled="showLoader  && validateSubmit"
            :class="['console-btn',
             (isAntsProducts ? 'console-btn-ants' : '') ,
              validateSubmit ? 'disabled-btn': '']" type="submit" @click="login">
              <div v-if="!loadingState.status.isLoaded">
                <rz-request-state :state="loadingState" />
              </div>
             <span v-if="showLogin">Sign in</span>
            </button>
            <div>
              <button  :class="['cancel-btn', (isAntsProducts ? 'cancel-btn-ants' : '')]"
              @click="back()">Back</button>
            </div>
          </div>
        </div>
      </div>
      <div class="signup-image-block" v-if="!isAntsProducts">
        <img class="bowler-img" src="@/assets/payment/signup-grp1.png" />
        <img class="batsmen-img" src="@/assets/payment/signup-grp2.png" />
        <img class="ring-img" src="@/assets/payment/signup-grp3.png" />
      </div>
      <div class="signup-image-block" v-else>
        <img class="bowler-img" src="@/assets/ants/signup-group-3.png" />
        <img class="batsmen-img" src="@/assets/ants/signup-group-2.png" />
        <img class="ring-img" src="@/assets/ants/signup-group-1.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Config from '@/config';
import { allMetaMixins } from '@/mixin/meta';

import { RequestState, RequestStateStatus } from '@/api/core';
import { FieldHandler } from '@/core/utils/formModels';
import { User } from '@/store/modules/user';

export default {
  mixins: allMetaMixins(),
  title: 'Sign in to your Roanuz account | Roanuz Cricket API',
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      page: (state) => state.page.activePage,
      tocLinks: (state) => state.link.cricketFooterTOC,
    }),
    validateSubmit() {
      if ((this.userInfo.email === null) || (this.userInfo.password === null)
        || (this.userInfo.email === '') || (this.userInfo.password === '')
      ) {
        return true;
      }
      return false;
    },
    loginParams() {
      if (this.$route && this.$route.query && this.$route.query.next_url) {
        return { next_url: this.$route.query.next_url };
      }
      if (this.$route && this.$route.query && this.$route.query.next) {
        return { next: this.$route.query.next };
      }
      return { next: '/user/project/create/' };
    },
  },

  watch: {
    $route(to, from) {
      this.isAntsProducts = this.$route.query.show_ants_product
       || new URLSearchParams(this.$route.query.next).get('show_ants_product');
      if (
        to.path !== from.path
        || to.params.sport !== from.params.sport
        || to.params.page !== from.params.page
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },

  serverPrefetch() {
    return this.fetchData();
  },

  mounted() {
    if (!this.dataLoaded) {
      this.fetchData();
    }
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value.includes(this.$route.meta.routeValue);
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
      }
    }
  },

  data() {
    return {
      loadingState: new RequestState(),
      userInfo: {
        email: null,
        password: null,
      },
      signUpInitiated: false,
      showLoader: false,
      showPassCode: false,
      showLogin: true,
      checked: false,
      homeUrl: Config.cricketHost,
      formFields: {
        email: new FieldHandler({
          errorMsg: 'No account exists for this email.',
          // eslint-disable-next-line max-len
          regExp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        }),
        password: new FieldHandler({
          errorMsg: 'The password you have entered is incorrect.',
          minValue: 8,
        }),
        response: new FieldHandler({
          errorMsg: 'Something went wrong!',
        }),
      },
      linkItemList: [
        {
          text: 'API terms of use.',
          url: `${Config.cricketHost}/legal/API-Terms-of-use/`,
        },
        {
          text: 'Terms & Privacy policy.',
          url: `${Config.cricketHost}/legal/Privacy-Policy/`,
        },
        // {
        //   text: 'Refund policy',
        //   url: `${Config.cricketHost}/legal/Refund-Policy/`,
        // },
      ],
      antsQueryParams: new URLSearchParams(this.$route.query.next).get('show_ants_product'),
      isAntsProducts: this.$route.query.show_ants_product
      || new URLSearchParams(this.$route.query.next).get('show_ants_product'),
      antsCurrency: this.$route.query.ants_currency
      || new URLSearchParams(this.$route.query.next).get('ants_currency'),
      queryParams: this.$route.query,
    };
  },
  methods: {
    fetchData() {
      const route = '/user/pages/';
      const routeValue = 'signup';
      return this.$store
        .dispatch('page/fetchActivePage', { route, routeValue })
        .catch((err) => {
          console.error('Error on fetch Active Page', err);
        });
    },

    back() {
      window.history.go(-1);
    },

    validateInput() {
      let count = 0;
      if (this.userInfo.email === '' || this.userInfo.email === null) {
        this.formFields.email.errorMsg = 'Please enter a valid email.';
      }
      if (this.userInfo.password === '' || this.userInfo.password === null) {
        this.formFields.password.errorMsg = 'Please enter your Password.';
      }
      if (!this.formFields.email.validateField(this.userInfo.email)) {
        count += 1;
      }

      if (!this.formFields.password.validateField(this.userInfo.password)) {
        count += 1;
      }
      return (count === 0);
    },


    processNext(user) {
      const successUrl = decodeURI(this.$route.query.next_url);
      this.$store.commit('boot/updateUser', { user });
      if (this.$route.query.next_url) {
        console.log(`Login Completed ${user.name}, Moving to ${successUrl}`);
        window.location.href = successUrl;
      } else if (this.$route.query.package) {
        // eslint-disable-next-line max-len
        const successRoute = this.$route.query.ants_currency
          // eslint-disable-next-line max-len
          ? decodeURI(`/user/project/create?package=${this.$route.query.package}&show_ants_product=${this.$route.query.show_ants_product}&ants_currency=${this.$route.query.ants_currency}`)
          // eslint-disable-next-line max-len
          : decodeURI(`/user/project/create?package=${this.$route.query.package}&show_ants_product=${this.$route.query.show_ants_product}`);
        console.log(`Signup Completed ${user.name}, Moving to ${successRoute}`);
        this.$router.push(successRoute);
      } else if (this.$route.query.show_ants_product && !this.$route.query.package) {
        const successRoute = Config.antsConsole;
        console.log(`Login Completed ${user.name}, Moving to ${successRoute}`);
        window.location.href = successRoute;
      } else {
        const successRoute = decodeURI(this.$route.query.next || '/user/project/create/');
        console.log(`Login Completed ${user.name}, Moving to ${successRoute}`);
        this.$router.push(successRoute);
      }
    },

    login() {
      this.showLoader = true;
      this.showLogin = false;
      if (this.validateInput()) {
        this.loadingState.status = RequestStateStatus.Loading;
        User.login(this.$apiInstance, this.userInfo)
          .then(({ user, error }) => {
            this.loadingState.status = RequestStateStatus.Loaded;
            this.showLogin = true;
            this.showLoader = false;
            if (error) {
              console.log('Invalid Login Request', error);
              this.formFields.response.showError = true;
              if (error.message === 'User not found') {
                this.formFields.response.errorMsg = 'No account exists for this email.';
              } else if (error.message === 'Wrong password') {
                this.formFields.response.errorMsg = 'The password you have entered is incorrect.';
              } else {
                this.formFields.response.errorMsg = error.message;
              }
            } else {
              this.processNext(user);
            }
          })
          .catch((err) => {
            this.formFields.response.showError = true;
            this.formFields.response.errorMsg = 'Internal Error.';
            this.showLoader = false;

            this.loadingState.status = RequestStateStatus.Error;
            this.loadingState.ref = err;
            console.error('Unhandled error during Login', err);
          });
      } else {
        this.showLogin = true;
      }
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.fetchData();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/views/form.scss';
</style>


<style lang="scss">
.image-area {
  .show-container {
    img {
      max-height: size(435);
    }
  }
}
.sign-up {
  .flex-col-right {
    .form-wrapper {
      padding-bottom: size(100);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(180);
      }
    }
  }
}

.flex {
  &-col {
    &-right {
      &.initiated {
          .tick-icon {
            background-image: url('../../assets/tick-icon.svg');
            background-repeat: no-repeat;
            background-position: 98% 50%;
            background-size: size(14) size(10);
          }
          .show {
            max-height: size(24);
          }
          .hide {
            max-height: 0;
          }
        }
    }
  }
}

.ants-product-login  {
   .flex-col-right  {
    font-family: var(--rz-ants-font);
    background: var(--rz-ants-color-primary-bg);
    color: var(--rs-white-color);

    .form-wrapper {
       .signup-text {
         color: var(--rs-white-color);
       }

       a {
        color: var(--rz-ants-primary-button-bg);
       }

       .form-group {
        label {
          color: var(--rz-ants-color-text1);
        }
        input {
          background: var(--rz-ants-primary-bg);
          padding: size(12) size(25) size(12) size(16);
          margin-bottom: size(8);
          color: var(--rs-white-color);
          border-color: transparent;
          outline: none;

          &:hover {
            border-color: transparent;
          }
          &:focus {
            border: size(1) solid var(--rz-ants-color-border);
            outline: none;
          }
        }
        input:-webkit-autofill {
          -webkit-text-fill-color: var(--rs-white-color);
           background: transparent;
           -webkit-tap-highlight-color: transparent;
           -webkit-box-shadow: 0 0 0 size(30) var(--rz-ants-color-border) inset;
        }
        input:-webkit-autofill:hover, input:-webkit-autofill:focus {
           background: var(--rz-ants-color-border);
        }
      }
    }
   }
}
</style>
