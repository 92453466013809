<template>
  <div class="on-boarding-wrap">
    <OnBoardingLoader
      v-if="projectSetupState.status.isError"
      :isErrorState="true"
      :title="'Oops! Something went wrong'"
      :description="'Make sure you got the correct url and try again after sometime.'"
    />
    <OnBoardingLoader
      v-else-if="projectSetupState.status.isLoading || projectSetupState.status.isNotInit"
      :title="'Processing Your Request!'"
      :description="'Give us a minute, as we are processing your request.'"
    />
    <OnBoardingLoader
      v-else-if="createProjectState.status.isLoading"
      :title="'Creating your Project!'"
      :description="'Give us a minute, as we are processing your request.'"
    />
    <div
      v-else
      class="page-container"
    >
      <div class="split-cols">
        <div class="column-1">
          <ChooseProject v-if="wizard.step.isSelectProject" />
          <ProjectNameStep
            v-if="wizard.step.isProjectName"
            ref="projectName"
          />
        </div>
        <div
          class="column-2 progress-bar"
        >
          <CartPreview />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.on-boarding-wrap {
  padding-top: size(65);
  margin-top: 0;
  margin: 0 size(30);
  @media screen and (min-width: $breakpoint-lg) {
    margin: 0 0 auto;
  }
  .page-container {
    max-width: var(--content-max-width);
    margin: auto;
  }

  .page-request-state {
    .error-msg {
      display: block;
    }
  }
}
</style>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';
import '@/assets/styles/views/onboarding.scss';
import { WizardStep } from '@/store/modules/payments';
import OnBoardingLoader from '@/components/Onboarding/OnBoardingLoader.vue';

import ChooseProject from '@/components/Payments/ChooseProject.vue';
import ProjectNameStep from '@/components/Payments/ProjectNameStep.vue';


import CartPreview from '@/components/Payments/CartPreview.vue';

export default {
  components: {
    OnBoardingLoader,
    ChooseProject,
    ProjectNameStep,
    CartPreview,
  },

  mixins: allMetaMixins(),
  title: 'Create Project | Roanuz Cricket API',

  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      createProjectState: (state) => state.payments.createProjectState,
      projects: (state) => state.boot.instance.projects,
      currency: (state) => state.user.activeCurrency,
      projectSetupState: (state) => state.payments.projectSetupState,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
    }),
  },


  mounted() {
    if (!this.wizard.licenses.length) {
      this.fetchData();
    }
  },

  data() {
    return {
      packageProjects: [],
    };
  },

  methods: {
    fetchData() {
      this.$store.dispatch('payments/prepareProjectSetup').then(() => {
        if (this.projects.length > 0) {
          for (let i = 0; i < this.projects.length; i += 1) {
            if (this.projects[i] && this.projects[i].project_license
            && this.projects[i].project_license[0]
            && this.projects[i].project_license[0].product === 'rs_package') {
              this.packageProjects.push(this.projects[i]);
            }
          }
        }
        if (this.projects && this.projects.length > 0
          && this.packageProjects && this.packageProjects.length > 0 && this.$route.query.package) {
          this.$store.commit('payments/updateWizard', { step: WizardStep.SelectProject });
        } else {
          this.$store.commit('payments/updateWizard', { step: WizardStep.ProjectName });
        }
        if (this.currency === 'inr') {
          this.cartPreviewInput.billingRegion = 'India';
        } else if (this.currency === 'usd') {
          this.cartPreviewInput.billingRegion = 'International';
        } else {
          this.cartPreviewInput.billingRegion = 'Europe';
        }
        if (this.$route.query && this.$route.query.license) {
          if (this.$route.query.license.split('_').join(' ').includes('annual')) {
            this.cartPreviewInput.selectedLicenseKey = this.$route.query.license;
            this.cartPreviewInput.prepayAmount = 50;
            this.cartPreviewInput.prepayAmountKey = this.$route.query.license;
            this.$store.dispatch('payments/fetchCartPreview');
          } else {
            this.cartPreviewInput.selectedLicenseKey = this.$route.query.license;
            this.$store.dispatch('payments/fetchCartPreview');
          }
        } else {
          this.cartPreviewInput.selectedLicenseKey = 'rs_package';
          this.cartPreviewInput.prepayAmount = 50;
          this.cartPreviewInput.prepayAmountKey = this.$route.query.package;
          this.$store.dispatch('payments/fetchCartPreview');
        }
      });
    },
  },
};
</script>
