<template>
  <div
    class="form-field-input"
    :class="{'error-field': formField.showError}"
  >
    <label class="field-label">{{formField.name}}</label>
    <div :class="[inputDivClass]">
      <span
        v-if="showSymbol"
        class="currency"
      >{{showSymbol}}</span>
      <input
        :type="formField.type"
        v-model="formField.model"
        :placeholder="formField.placeholder"
        :name="formField.formName"
        :autocomplete='formField.autocompleteName'
        :disabled="formField.disabled"
        @blur="onBlur"
        @input="onInput"
        @change="$emit('change', $event.target.value)"
      />
      <p v-if="formField.showError" class="error-msg">
        {{errorMessage || formField.message}}
      </p>
      <p v-else-if="helpText">
        {{helpText}}
      </p>
    </div>
  </div>
</template>
<style lang="scss">
  .form-field-input {
    .amount-div{
      margin-bottom: size(36);

      &.error-field {
        .currency {
          top: 30%;
        }
      }
      .currency {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: size(10);
        display: inline-block;
      }
      input {
        border: size(1) solid #c4c4c4;
        border-radius: size(6);
        padding: size(10);
        padding-left: size(22);
      }
      &.error-field {
        input {
          border: size(1) solid #d83e3e;
        }
      }
    }
  }
</style>
<script>
export default {
  props: {
    formField: Object,
    inputDivClass: String,
    showSymbol: {
      type: String,
      required: false,
      default: null,
    },
    errorMessage: {
      type: String,
      required: false,
      default: null,
    },
    helpText: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    onBlur(event) {
      this.formField.touched = true;
      this.$emit('blur', event.target.value);
    },
    onInput(event) {
      if (this.formField.validateOnInput) {
        this.formField.touched = true;
      }
      this.$emit('input', event.target.value);
    },
  },
};
</script>
