<template>
  <SimplePaymentMethod
    :isSelected="wizard.selectedPaymentMethod && wizard.selectedPaymentMethod.key == method.key"
    :name="method.name"
    :iconClass="method.icon"
    :method="method"
    :isAntsProducts="isAntsProducts"
    @change="$emit('change', method)"
  >
    <template #title>
      {{replaceCurrencyOneUnit(method.title, currency)}}
    </template>
    <template #desc>
      <div class="error-field" v-if="errorMessage || gatewayErrorMessage">
        <p class="error-msg">
          <svg class="warning-icon">
            <use v-bind="{'xlink:href':'#warning-icon'}" />
          </svg>
          {{gatewayErrorMessage || errorMessage}}
        </p>
      </div>
    </template>
  </SimplePaymentMethod>
</template>
<script>
import { mapState } from 'vuex';
import Config from '@/config';
import SimplePaymentMethod from '@/components/Payments/SimplePaymentMethod.vue';

export default {
  components: {
    SimplePaymentMethod,
  },
  props: {
    method: Object,
    errorMessage: {
      type: String,
      required: false,
      default: null,
    },
    isAntsProducts: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      cartPreview: (state) => state.payments.cartPreview,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      currency: (state) => state.payments.cartPreview.currency,
    }),
  },
  data() {
    return {
      gatewayErrorMessage: null,
    };
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    buildParams(rpOrderId, metadata) {
      const key = Config.razorpayKey;

      return {
        key,
        amount: this.cartPreview.grand_total,
        currency: this.cartPreview.currency.toUpperCase(),
        order_id: rpOrderId,
        prefill: {
          name: this.cartPreview.billing.contact_name,
          email: this.cartPreview.billing.contact_email,
        },
        theme: {
          color: '#2F8CE4',
        },
        notes: metadata,
      };
    },

    makePayment({ rpOrderId, metadata }) {
      return new Promise((resolve, reject) => {
        const params = this.buildParams(rpOrderId, metadata);
        const payload = {
          ...params,
          handler: (resp) => {
            resolve(resp);
          },
          modal: {
            ondismiss: () => {
              const msg = 'You have cancelled it. Try again.';
              this.gatewayErrorMessage = msg;
              reject(new Error(msg));
            },
          },
        };

        // eslint-disable-next-line no-undef
        const razorpay = new Razorpay(payload);
        razorpay.on('payment.failed', (err) => {
          console.log('Razorpay Payment Error');
          console.log(err);
          this.gatewayErrorMessage = err.error.message;
          reject(err);
        });
        razorpay.open();
      });
    },
  },
};
</script>
