<template>
  <div class="user user-links">
    <h1 v-if="bootData.user.key">Hey {{bootData.user.name}}</h1>
    <router-link
      class="login-info-link"
      :to="{ name: 'login'}"
    >Log in</router-link>
    <router-link
      class="login-info-link"
      :to="{ name: 'signUp'}"
    >Create Account</router-link>
    <router-link
      class="login-info-link"
      :to="{ name: 'planReview'}"
    >Create Project</router-link>
    <router-link
      class="login-info-link"
      :to="{ name: 'planReview'}"
    >Payments</router-link>
    <router-link
      class="login-info-link"
      :to="{ name: 'userMe'}"
    >Me</router-link>
  </div>
</template>
<style lang="scss" scoped>
  .user {
    &.user-links {
      a {
        display: block;
        margin: 2rem;
      }
    }
  }
</style>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';

export default {
  mixins: allMetaMixins(),
  title: 'Links',
  computed: {
    ...mapState({
      bootData: (state) => state.boot.instance,
    }),
  },
};
</script>
