<template>
  <SimplePaymentMethod
    :isSelected="wizard.selectedPaymentMethod && wizard.selectedPaymentMethod.key == method.key"
    :name="method.name"
    :iconClass="method.icon"
    :method="method"
    @change="$emit('change', method)"
    @saveCard="$emit('saveCard', $event)"
    :isAntsProducts="isAntsProducts"
  >
    <template #title>
      {{replaceCurrencyOneUnit(method.title, currency)}}
    </template>
    <template #icon>
      <div class="payment-logo stripe">
        <img src="@/assets/payment/stripe_logo.png" v-if="!isAntsProducts" alt="Stripe Logo">
        <img src="@/assets/ants/stripe.svg" v-else alt="Stripe Logo">
      </div>
    </template>
    <template #desc>
      <div id="card-element" ref="stripeElement">
        <!-- Elements will create input elements here -->
      </div>
      <div class="error-field" v-if="errorMessage || stripeErrorMessage">
        <p class="error-msg">
          <svg class="warning-icon">
            <use v-bind="{'xlink:href':'#warning-icon'}" />
          </svg>
          {{stripeErrorMessage || errorMessage}}
        </p>
      </div>
    </template>
  </SimplePaymentMethod>
</template>
<style lang="scss">
  .stripe-card-box {
    border: 1px solid #c2c2c2;
    margin-top: size(16);
    padding: size(12);
    border-radius: size(5);

    &__focus {
      border-color: var(--onboard-link-color);
    }
  }
  .ants-card-box {
    border-color: var(--rz-ants-color-border);
    margin-bottom: size(8);
    padding: size(12) size(25) size(12) size(16);
    border-radius: size(4);
    background: var(--rz-ants-color-border);

    &__focus {
      border-color: transparent;
    }
  }
</style>
<script>
import { mapState } from 'vuex';
import Config from '@/config';
import SimplePaymentMethod from '@/components/Payments/SimplePaymentMethod.vue';

export default {
  components: {
    SimplePaymentMethod,
  },
  props: {
    method: Object,
    errorMessage: {
      type: String,
      required: false,
      default: null,
    },
    isAntsProducts: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      cartPreview: (state) => state.payments.cartPreview,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      currency: (state) => state.payments.cartPreview.currency,
    }),
  },
  data() {
    return {
      stripe: null,
      cardEle: null,
      stripeErrorMessage: null,
    };
  },
  mounted() {
    if (!this.stripe) {
      this.loadStripeElements();
    }

    if (!this.cardEle) {
      this.prepareCardElement();
    }

    // this.cardEle.focus();
  },
  beforeDestroy() {
    if (this.cardEle) {
      this.cardEle.destroy();
      this.cardEle = null;
    }

    if (this.stripe) {
      this.stripe = null;
    }
  },
  methods: {
    loadStripeElements() {
      const { stripeKey } = Config;
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(stripeKey);
    },

    prepareCardElement() {
      const cardEle = this.$refs.stripeElement;
      if (cardEle) {
        const elements = this.stripe.elements();
        const style = {
          base: {
            color: this.isAntsProducts ? '#ffffff' : '#32325d',
            fontSize: '17px',
          },
        };

        const classes = {
          base: this.isAntsProducts ? 'ants-card-box' : 'stripe-card-box',
          focus: this.isAntsProducts ? 'ants-card-box__focus' : 'stripe-card-box__focus',
        };
        this.cardEle = elements.create('card', { style, hidePostalCode: true, classes });
        this.cardEle.mount('#card-element');
      }
    },

    makeBillingDetail() {
      return {
        name: this.cartPreviewInput.billing.contact_name,
        email: this.cartPreviewInput.billing.contact_email,
        address: {
          country: this.cartPreviewInput.billing.country_code,
          postal_code: this.cartPreviewInput.billing.zip_code,
        },
      };
    },

    makePayment({ clientSecret, metadata }) {
      this.stripeErrorMessage = '';
      return this.stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: this.cardEle,
            billing_details: this.makeBillingDetail(),
            metadata,
          },
        },
      ).then((result) => {
        if (result.error && result.error.message) {
          console.log('Stripe Payment Error');
          console.log(result);
          this.stripeErrorMessage = result.error.message;
          return Promise.reject(result.error);
        }

        if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
          return result;
        }

        return null;
      });
    },

    setupFuturePayment({ clientSecret, metadata }) {
      this.stripeErrorMessage = '';
      return this.stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: this.cardEle,
            billing_details: this.makeBillingDetail(),
            metadata,
          },
        },
      ).then((result) => {
        if (result.error && result.error.message) {
          console.log('Stripe Card Setup Error');
          console.log(result);
          this.stripeErrorMessage = result.error.message;
          return Promise.reject(result.error);
        }

        if (result.setupIntent && result.setupIntent.status === 'succeeded') {
          return result;
        }

        return null;
      });
    },
  },
};
</script>
