<template>
  <div class="select-payment-wrapper">
    <h2>Select Payment Method</h2>
    <section class="variations-types-wrap">
      <div
        v-for="method in wizard.billingMethods"
        :key="method.key"
        class="variation"
        :class="method.key"
        @click="updateBillingMethod(method.key)"
      >
        <div class="form-group custom-radio">
          <input
            type="radio"
            v-model="selectedBillingMethod"
            :value="method.key"
          />
          <span class="checkmark"></span>
          <h3>{{replaceCurrencyOneUnit(method.title, currency)}}</h3>
        </div>
        <p>{{method.desc}}</p>
      </div>
    </section>
    <div class="btn-wrap">
      <button class="btn focus" @click="nextStep">
        Continue
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.select-payment-wrapper {
  .variations-types-wrap {
    .variation {
      &.free {
        display: none;
      }
      &.prepay {
        border-bottom: none;
      }
    }
  }
}
</style>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';

export default {
  mixins: allMetaMixins(),
  title: 'Choose a Payment Method | Roanuz Cricket API',
  computed: {
    ...mapState({
      currency: (state) => state.payments.cartPreview.currency,
      wizard: (state) => state.payments.wizard,
    }),
    selectedBillingMethod: {
      get() {
        return this.$store.state.payments.wizard.selectedBillingMethod;
      },
      set(val) {
        this.updateBillingMethod(val);
      },
    },
  },
  methods: {
    updateBillingMethod(key) {
      this.$store.commit('payments/updateWizard', { selectedBillingMethod: key });
    },
    nextStep() {
      this.$store.commit('payments/wizardRequestNextStep');
    },
  },
};
</script>
