<template>
  <div :class="['on-boarding-wrap',
    (formFields.product.showError ? 'error-field' : '')]">
    <h2>Save with Committed-use</h2>
    <div
      v-if="cartPreviewInput.canChangeRegion"
      class="form-group">
      <label class="field-label">Billing Region</label>
      <CurrencyDropdown
        :defaultValue="currency"
        :placeholder="'Preferred Billing Region'"
        @change="updateCurrency($event)"/>
    </div>
    <section class="variations-types-wrap">
      <div v-if="!dataLoaded">
        <rz-request-state :state="loadingState" />
      </div>
      <template v-else>
        <template v-for="item in wizard.committedUseItems">
          <div :key="item.product" class="variation">
            <div class="form-group custom-radio">
              <input type="radio"
                :checked="item.product === selectedItem"
                @change="selectItem(item)"
                name="behaviour"
                :value="item.product"
              />
              <span class="checkmark"></span>
            </div>
            <p>
              Pay
              <strong>
                {{
                formatCurrency(
                  item.price.unit_amount[currency],
                  currency)
                }}
              </strong>
              and use for
              <strong>
                {{
                  formatCurrency((
                    item.price.unit_amount[currency] +
                    item.price.extra_credits_amount[currency]),
                    currency
                  )
                }}
              </strong>
            </p>
          </div>
        </template>
      </template>
    </section>
    <p v-if="formFields.product.showError" class="error-msg">
      {{ formFields.product.errorMsg }}
    </p>
    <div class="btn-wrap">
      <button
        class="btn focus"
        @click="confirmCommittedUse()"
      >Continue</button>
      <span
        class="skip-btn"
        @click="cancelCommittedUse()"
      >Proceed without committing</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';
import { RequestState, RequestStateStatus } from '@/api/core';
import { WizardStep } from '@/store/modules/payments';

import { FieldHandler } from '@/core/utils/formModels';
import { PaymentInfo } from '@/store/modules/paygo';
import CurrencyDropdown from '@/components/Onboarding/CurrencyDropdown.vue';

export default {
  components: {
    CurrencyDropdown,
  },

  mixins: allMetaMixins(),
  title: 'Save more with Committed use | Roanuz Cricket API',

  computed: {
    ...mapState({
      currency: (state) => state.payments.cartPreviewInput.billingCurrency,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      cartPreview: (state) => state.payments.cartPreview,
      wizard: (state) => state.payments.wizard,
      dataLoaded: (state) => state.payments.wizard.committedUseItems !== null,
      committedUseItems: (state) => state.payments.wizard.committedUseItems,
      selectedItem: (state) => state.payments.cartPreviewInput.committedUseProduct,
    }),
  },

  data() {
    return {
      loadingState: new RequestState(),
      formFields: {
        product: new FieldHandler({
          errorMsg: 'Choose at least one committed-use option or click on Proceed without committing',
        }),
      },
      bigbashPromoPlan: null,
      bigbashPlanDetails: [
        {
          product: 'rs_paygo_bbl2021_season_lite',
          name: 'Big Bash League 2021 Season Lite',
          price: {
            unit_amount: {
              usd: 240,
              inr: 16000,
              eur: 210,
            },
          },
        },
        {
          product: 'rs_paygo_bbl2021_season_premium',
          name: 'Big Bash League 2021 Season Premium',
          price: {
            unit_amount: {
              usd: 460,
              inr: 30800,
              eur: 405,
            },
          },
        },
      ],
    };
  },

  serverPrefetch() {
    return this.fetchData();
  },

  mounted() {
    if (!this.dataLoaded) {
      this.fetchData();
    }
  },

  methods: {
    fetchData() {
      const { currency } = this;
      this.loadingState.status = RequestStateStatus.Loading;
      this.$store.dispatch('payments/fetchCommittedUseItems', { currency }).then(() => {
        this.loadingState.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        console.log('Error while fetchCommittedUseItems');
        console.error(err);
        this.loadingState.status = RequestStateStatus.Error;
        this.loadingState.ref = err;
      });
    },

    updateCurrency(currency) {
      this.$store.commit('user/updateActiveCurrency', currency);
      this.$store.dispatch('payments/updateCart', { currency });
    },

    selectItem(item) {
      const committedUseProduct = item.product;
      this.$store.dispatch('payments/updateCart', { committedUseProduct });
    },

    cancelCommittedUse() {
      if (this.cartPreview.project.project_license[0].product === 'rs_paygo_standard') {
        const committedUseProduct = null;
        this.$store.dispatch('payments/updateCart', { committedUseProduct });
        this.nextStep();
      } else {
        if (this.$route.query) {
          this.$router.push({ query: { step: 'billing' } });
        }
        this.$store.commit('payments/updateWizard', { step: WizardStep.Billing });
      }
    },

    confirmCommittedUse() {
      this.nextStep();
    },

    getOfferPercentage(regular, extraCredits) {
      return PaymentInfo.getOfferPercentage(regular, extraCredits);
    },

    nextStep() {
      this.$store.commit('payments/wizardRequestNextStep');
    },
  },
};
</script>
<style lang="scss" scoped>
.on-boarding-wrap {
  .skip-btn {
    margin-left: size(15);
    @media screen and (min-width: $breakpoint-md) {
      margin-left: size(30);
    }
  }
}
.promo-title {
  font-size: size(20);
  font-weight: 600;
  padding-top: size(20);
  display: inline-block;
}
.promo {
  .form-group {
    margin-bottom: 0;
  }
  .form-content {
    padding-top: size(5);
    .features {
      ul {
        padding-top: size(20);
        padding-left: size(20);
        padding-bottom: size(8);
        list-style-type: disc;
        li {
          padding-bottom: size(10);
          line-height: size(22);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      h4 {
        font-size: size(16);
        font-weight: 600;
        padding-top: size(20);
      }
    }
  }
}
</style>
