var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataLoaded)?_c('div',{staticClass:"sign-up signup-page",class:[(_vm.isAntsProducts ? 'ants-sign-up' : '')]},[_c('div',{staticClass:"flex",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createAccount($event)}}},[_c('div',{staticClass:"flex-col-right",class:[_vm.signUpInitiated ? 'initiated' : null]},[_c('div',{staticClass:"form-wrapper"},[_c('h1',{staticClass:"signup-text"},[_vm._v("Create your Roanuz account")]),(_vm.isAntsProducts && !_vm.antsQueryParams)?_c('p',{staticClass:"login-info"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"login-info-link",attrs:{"to":{ name: 'login', query: Object.assign({}, _vm.$route.query) }}},[_vm._v("Sign in")])],1):_c('p',{staticClass:"login-info"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"login-info-link",attrs:{"to":{ name: 'login', query: _vm.loginParams }}},[_vm._v("Sign in")])],1),_c('div',{staticClass:"register-form"},[_c('div',{class:['form-group', (_vm.isAntsProducts ? 'ants-form-group' : ''),
           (_vm.formFields.name.showError ? 'error-field' : '')]},[_c('label',{staticClass:"field-label",attrs:{"for":"fullname"}},[_vm._v("Full name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfo.name),expression:"userInfo.name"}],class:[!_vm.formFields.name.showError ? 'tick-icon' : null],attrs:{"type":"text","name":"fullname","id":"fullname","placeholder":" ","autocomplete":"off","autofocus":""},domProps:{"value":(_vm.userInfo.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfo, "name", $event.target.value)}}}),(_vm.formFields.name.showError)?_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.formFields.name.errorMsg))]):_vm._e()]),_c('div',{class:['form-group',
          (_vm.isAntsProducts ? 'ants-form-group' : ''),(_vm.formFields.email.showError ? 'error-field' : '')]},[_c('label',{staticClass:"field-label",attrs:{"for":"workemail"}},[_vm._v("Work email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfo.email),expression:"userInfo.email"}],class:[!_vm.formFields.email.showError ? 'tick-icon' : null],attrs:{"type":"email","name":"workemail","id":"workemail","placeholder":"","autocomplete":"off"},domProps:{"value":(_vm.userInfo.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfo, "email", $event.target.value)}}}),(_vm.formFields.email.showError)?_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.formFields.email.errorMsg))]):_vm._e()]),_c('div',{class:['form-group password-div',
          (_vm.isAntsProducts ? 'ants-form-group' : ''),
           (_vm.formFields.password.showError ? 'error-field' : '')]},[_c('label',{staticClass:"field-label password",attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfo.password),expression:"userInfo.password"}],class:[!_vm.formFields.password.showError ? 'tick-icon' : null],attrs:{"type":"password","name":"password","id":"password","placeholder":" ","autocomplete":"on"},domProps:{"value":(_vm.userInfo.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfo, "password", $event.target.value)}}}),(_vm.formFields.password.showError)?_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.formFields.password.errorMsg))]):_vm._e()]),_c('div',{class:['form-group',
          (_vm.isAntsProducts ? 'ants-form-group' : ''),
           (_vm.formFields.companyName.showError ? 'error-field' : '')]},[_c('label',{staticClass:"field-label",attrs:{"for":"fullname"}},[_vm._v("Company")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfo.company_name),expression:"userInfo.company_name"}],class:[!_vm.formFields.companyName.showError ? 'tick-icon' : null],attrs:{"type":"text","name":"company","id":"company","placeholder":" ","autocomplete":"off"},domProps:{"value":(_vm.userInfo.company_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfo, "company_name", $event.target.value)}}}),(_vm.formFields.companyName.showError)?_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.formFields.companyName.errorMsg))]):_vm._e()]),_c('div',{class:['form-group', (_vm.isAntsProducts ? 'ants-form-group' : '') ]},[_c('label',{staticClass:"field-label",attrs:{"for":"fullname"}},[_vm._v("Phone number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfo.phone_number),expression:"userInfo.phone_number"}],attrs:{"type":"number","id":"phoneNumber","name":"phoneNumber","placeholder":" ","autocomplete":"off"},domProps:{"value":(_vm.userInfo.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfo, "phone_number", $event.target.value)}}})]),_vm._m(0),(_vm.errorMessage)?_c('div',{staticClass:"alert alert-warning"},[_c('p',[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_c('button',{class:[ 'console-btn' , (_vm.isAntsProducts ? 'console-btn-ants' : '') ,
          _vm.validateSubmit ? 'disabled-btn': ''],attrs:{"disabled":_vm.showLoader && _vm.validateSubmit,"type":"submit"},on:{"click":_vm.createAccount}},[(!_vm.loadingState.status.isLoaded)?_c('div',[_c('rz-request-state',{attrs:{"state":_vm.loadingState}})],1):_vm._e(),(_vm.showSignup)?_c('span',[_vm._v("Create Account")]):_vm._e()]),(!_vm.isAntsProducts)?_c('div',[_c('button',{class:['cancel-btn' , (_vm.isAntsProducts ? 'cancel-btn-ants' : '')],on:{"click":function($event){return _vm.back()}}},[_vm._v("Back")])]):_vm._e()])])]),(!_vm.isAntsProducts)?_c('div',{staticClass:"signup-image-block"},[_c('img',{staticClass:"bowler-img",attrs:{"src":require("@/assets/payment/signup-grp1.png")}}),_c('img',{staticClass:"batsmen-img",attrs:{"src":require("@/assets/payment/signup-grp2.png")}}),_c('img',{staticClass:"ring-img",attrs:{"src":require("@/assets/payment/signup-grp3.png")}})]):_c('div',{staticClass:"signup-image-block"},[_c('img',{staticClass:"bowler-img",attrs:{"src":require("@/assets/ants/signup-group-3.png")}}),_c('img',{staticClass:"batsmen-img",attrs:{"src":require("@/assets/ants/signup-group-2.png")}}),_c('img',{staticClass:"ring-img",attrs:{"src":require("@/assets/ants/signup-group-1.png")}})])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"check-box-group terms-and-conditions"},[_vm._v(" By clicking Create Account, you agree to our and "),_c('span',{staticClass:"link-item"},[_c('a',{attrs:{"href":"https://www.cricketapi.com/legal/Terms-of-use/","target":"_blank"}},[_vm._v(" Terms & Conditions")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://www.cricketapi.com/legal/Privacy-Policy/","target":"_blank"}},[_vm._v("Privacy Policy")])])])}]

export { render, staticRenderFns }