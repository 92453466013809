<template>
  <div>
    <a
      href="https://console.roanuz.com/"
      title="Console" rel="noopener">
      <h1>Hey {{bootData.user.name}}</h1>
    </a>
    <p>{{bootData}}</p>
  </div>
</template>
<style lang="scss" scoped>
</style>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';

export default {
  mixins: allMetaMixins(),
  title: 'About my Roanuz account',
  computed: {
    ...mapState({
      bootData: (state) => state.boot.instance,
    }),
  },
};
</script>
