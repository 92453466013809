<template>
  <section class="payment-section" :class="['payment-section', (isAntsProducts ? 'ants-payment' : '' )]">
    <h4 class="step-label">STEP 3 OF 3</h4>
    <h2>{{title}}</h2>
    <div class="billing-info">
      <BillingAddress
        ref="billingAddress"
        @change="onBillingChange"
        :isAntsProducts="isAntsProducts"
      />
      <div class="form-wrapper-section">
        <PaymentMethods
          ref="paymentMethods"
          @change="onPaymentChange"
          @saveCard="onSavingCard"
          :isAntsProducts="isAntsProducts"
        />
      </div>
      <div class="btn-wrap">
        <rz-request-state :state="cartSaveState" />
        <button
          v-if="cartPreview.isPaymentRequired"
          class="btn focus"
          :disabled="cartSaveState.status.isLoading"
          :class="[cartSaveState.status.isLoading ? 'disabled' : '']"
          @click="doProcced"
        >
          Pay {{ formatCurrency(cartPreview.grand_total, cartPreview.currency) }}
        </button>
        <button
          v-else
          class="btn focus"
          :disabled="cartSaveState.status.isLoading"
          :class="[cartSaveState.status.isLoading ? 'disabled' : '']"
          @click="doProcced"
        >
          Proceed
        </button>
        <div
          v-if="errorMessage"
          class="form-errors error-field"
        >
          <p class="error-msg">{{errorMessage}}</p>
        </div>
        <p class="terms-and-conditions">
          By clicking Continue, you accept to our
          <a
            href="//www.cricketapi.com/legal/API-Terms-of-use"
            title="Terms and Conditions" target="_blank"
          > Terms and Conditions
            <svg class="external-icon"><use v-bind="{'xlink:href':'#external-icon'}"></use></svg>
          </a></p>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
.payment-section {
  @media screen and (min-width: $breakpoint-md) {
    padding-top: size(55);
  }
  .step-label {
    font-size: size(12);
    line-height: size(17);
    font-weight: 500;
    color: var(--rs-black-color);
    margin-bottom: size(10)
  }
}
.disabled {
  cursor: not-allowed;
}
.billing-info {
  --gray-line: #c4c4c4;

  --billing-info-box-width: auto;

  @media screen and (min-width: $breakpoint-lg) {
    --billing-info-box-width: #{size(540)};
  }

  margin-top: size(20);
  font-size: var(--onboarding-description-font-size);
  line-height: var(--onboarding-description-line-height);
  width: var(--billing-info-box-width);

  .form-wrapper-section {
    padding: size(30);
    border-radius: size(8);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
      0 size(2) size(1) rgba(0, 0, 0, 0.06),
      0 size(1) size(1) rgba(0, 0, 0, 0.08);
    background: var(--rs-white-color);
  }
  .terms-and-conditions {
    font-weight: 400;
    font-size: size(16);
    line-height: size(19);
    padding: size(20) 0 size(50);
    color: var(--rs-black-color);
    a {
      color: var(--rz-link-color);
    }
    svg {
      display: inline-block;
      vertical-align: middle;
      width: size(8);
      height: size(8);
      margin-left: size(5);
    }
  }

  h5 {
    font-weight: bold;
    color: var(--ca-title-color);
  }

  .page-request-state {
    .error-msg {
      display: block;
    }
  }
}

.ants-payment {
  >* {
    font-family: var(--rz-ants-font);
  }
  .prompt-box {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
  }
  .step-label, h2 {
    color: var(--rs-white-color);
    font-family: var(--rz-ants-font);
  }
  .btn-wrap {
    .btn {
      background: #F5FF78;
      color: var(--rz-ants-color-text2);
      padding: size(12);
      border: none;
      font-weight: 500;
    }
    p {
      color: var(--rs-white-color);
    }
    a {
      color: #F5FF78;
      padding: 0;

      svg {
        fill: #F5FF78;
        stroke: #F5FF78;
      }
    }
  }
}
</style>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';
import { CheckoutResultKind } from '@/store/modules/payments';
import { RequestStateStatus } from '@/api/core';
import BillingAddress from '@/components/Payments/BillingAddress.vue';
import PaymentMethods from '@/components/Payments/PaymentMethods.vue';

export default {
  components: {
    BillingAddress,
    PaymentMethods,
  },
  props: {
    title: String,
  },

  mixins: allMetaMixins(),

  data() {
    return {
      errorMessage: '',
      billingAddressChanged: false,
      lastTransactionKey: null,
      invoiceResponse: null,
      invoiceEdit: false,
      saveCard: false,
      isAntsProducts: this.$route.query.show_ants_product,
    };
  },

  mounted() {
    document.title = this.isAntsProducts ? 'Complete Payment | Roanuz Ants' : 'Complete Payment | Roanuz Cricket API';
  },
  watch: {
    isAntsProducts() {
      document.title = this.isAntsProducts ? 'Complete Payment | Roanuz Ants' : 'Complete Payment | Roanuz Cricket API';
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.activeUser,
      wizard: (state) => state.payments.wizard,
      cartPreview: (state) => state.payments.cartPreview,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      cartSaveState: (state) => state.payments.cartSaveState,
    }),
  },
  methods: {
    validate() {
      if (!this.wizard.selectedPaymentMethod && this.cartPreview.valid_payment_methods.length >= 1) {
        this.errorMessage = 'Please select a payment method';
        return false;
      }

      if (!this.$refs.billingAddress.isFormValid()) {
        this.errorMessage = 'Please fix the errors shown and try again.';
        return false;
      }

      this.errorMessage = '';
      return true;
    },

    onBillingChange() {
      this.billingAddressChanged = true;
      this.invoiceEdit = true;
      this.errorMessage = '';
    },

    onPaymentChange() {
      this.invoiceEdit = true;
    },

    onSavingCard(event) {
      this.saveCard = event.target.value === 'on';
    },

    doProcced() {
      if (this.cartSaveState.status.isLoading) {
        return;
      }

      if (!this.validate()) {
        return;
      }
      this.$store.commit('payments/updateCartSaveState', { status: RequestStateStatus.Loading });
      let billingUpdateFn = null;

      if (!this.cartPreviewInput.billingKey) {
        billingUpdateFn = 'payments/createBillingProfile';
      } else if (this.billingAddressChanged
      || this.cartPreview.valid_payment_methods.length <= 0) {
        billingUpdateFn = 'payments/updateBillingProfile';
      } else {
        this.handleLastPromise(this.onBillingAddressReady());
      }

      if (billingUpdateFn) {
        const profile = this.getBillingPayload();
        this.$store.commit('payments/updateBillingProfile', { profile });
        this.$store.dispatch(billingUpdateFn).then(() => {
          return this.handleLastPromise(this.onBillingAddressReady());
        }).catch((err) => {
          this.saveStateError(err);
        });
      }
    },

    getBillingPayload() {
      const profile = this.$refs.billingAddress.formData();
      if (profile.country_code !== 'IN') {
        profile.state_name = profile.state;
      }
      if (this.cartPreviewInput.billingKey) {
        profile.key = this.cartPreviewInput.billingKey;
      }
      return profile;
    },

    saveStateError(err) {
      console.log('Error while invoice');
      console.error(err);
      this.$store.commit('payments/updateCartSaveState', {
        status: RequestStateStatus.Loaded,
        msg: err,
      });
    },

    onBillingAddressReady() {
      if (this.cartPreview.isPaymentRequired) {
        if (this.cartPreviewInput.invoiceKey) {
          if (this.invoiceEdit) {
            return this.wizard.editInvoice(this.$apiInstance, this.cartPreviewInput, this.lastTransactionKey)
              .then(() => {
                return this.makePayment();
              });
          }
          return this.makePayment();
        }

        return this.createInvoice();
      }

      return this.setupBillingProfile();
    },

    gatewayMetadata() {
      return {
        rz_gateway_version: '1.0',
        rz_transaction_key: this.lastTransactionKey,
        rz_invoice_key: this.cartPreviewInput.invoiceKey,
        rz_user_key: this.user.key,
      };
    },

    gatewayParams(data) {
      const params = { metadata: this.gatewayMetadata() };
      const gateway = this.wizard.selectedPaymentMethod.payment_gateway;

      if (gateway === 'stripe') {
        params.clientSecret = data.client_secret;
      } else if (gateway === 'razorpay') {
        params.rpOrderId = data.razorpay_order_id;
      }
      return params;
    },

    makePayment() {
      const medium = this.wizard.selectedPaymentMethod.payment_medium;
      if (medium === 'invoice') {
        this.updateResult({ kind: CheckoutResultKind.InvoiceCreated });
        return null;
      }
      return this.wizard.createTransaction(
        this.$apiInstance, this.cartPreviewInput, this.lastTransactionKey, this.saveCard,
      ).then((res) => {
        const { data } = res.data;
        console.log('Create transaction response', data);
        this.lastTransactionKey = data.transaction.key;
        const params = this.gatewayParams(data);
        const pmkey = this.wizard.selectedPaymentMethod.key;

        return this.$refs.paymentMethods.makePayment(pmkey, params);
      }).then((res) => {
        console.log('Payment Gateway Response', res);
        const payload = {
          transaction_key: this.lastTransactionKey,
          payload: res,
        };
        this.updateResult({ kind: CheckoutResultKind.PaymentReceived });
        return this.wizard.markPaymentReceived(this.$apiInstance, this.cartPreviewInput, payload);
      });
    },

    createInvoice() {
      return this.wizard.createInvoice(
        this.$apiInstance, this.cartPreviewInput,
      ).then((res) => {
        const { data } = res.data;
        console.log('Create invoice response', data);
        const invoiceKey = data.key;
        this.$store.commit('payments/updateCart', { invoiceKey });

        return this.makePayment();
      });
    },

    setupFutureBilling() {
      return this.wizard.setupFuturePayments(
        this.$apiInstance, this.cartPreviewInput,
      ).then((res) => {
        console.log('Future payment Response', res.data.data);
        const pmkey = this.wizard.selectedPaymentMethod.key;
        const params = this.gatewayParams(res.data.data);

        return this.$refs.paymentMethods.setupFuturePayment(pmkey, params);
      }).then((res) => {
        console.log('Strip response', res);
        return this.wizard.confirmFuturePayments(this.$apiInstance, this.cartPreviewInput, res);
      }).then((res) => {
        if (!res.data.error) {
          console.log('Future payment Confirm', res.data.data);
          this.updateResult({ kind: CheckoutResultKind.CardVerified });
        } else if (res.data.error) {
          if (res.data.error.code === 40201) {
            this.errorMessage = 'Card verification failed. Your card doesn\'t support future payments.'
            + ' Please try with another card.'
            + ' You can also choose to prepay, opt for committed-use or pay by invoice.';
          } else if (res.data.error.code === 40202) {
            this.errorMessage = 'Card verification failed. Your card\'s validity ends before the next billing date.'
            + ' Please try with another card. You can also choose to prepay, opt for committed-use or pay by invoice.';
          } else {
            this.errorMessage = res.data.error.message;
          }
          this.$store.commit('payments/updateCartSaveState', {
            status: RequestStateStatus.Loaded,
          });
          this.updateResult({ kind: CheckoutResultKind.PaymentError });
          return res;
        }
        return res;
      });
    },

    handleLastPromise(action) {
      if (action) {
        return action.then((res) => {
          if (res && res.data && res.data.error) {
            return res;
          }
          return this.markComplete();
        }).catch((err) => {
          this.saveStateError(err);
        });
      }

      this.markComplete();
      return null;
    },

    updateResult(props) {
      this.$store.commit('payments/updateWizardResult', { props });
    },

    markComplete() {
      if (this.wizard.step.isPayment && this.cartPreviewInput.invoiceKey) {
        return this.wizard.confirmInvoice(this.$apiInstance, this.cartPreviewInput).then(() => {
          this.$store.commit('payments/updateCartSaveState', { status: RequestStateStatus.Loaded });
          this.$store.commit('payments/wizardMarkComplete');
        }).catch((err) => {
          this.saveStateError(err);
        });
      }
      this.$store.commit('payments/updateCartSaveState', { status: RequestStateStatus.Loaded });
      this.$route.push({
        query: {
          show_ants_product: true,
          forceLogin: this.$route.query.forceLogin,
        },
      });
      return this.$store.commit('payments/wizardMarkComplete');
    },

    setupBillingProfile() {
      if (this.wizard && this.wizard.selectedPaymentMethod
      && this.wizard.selectedPaymentMethod.payment_gateway) {
        const gateway = this.wizard.selectedPaymentMethod.payment_gateway;
        if (gateway === 'stripe') {
          return this.setupFutureBilling();
        }
      }

      // Change to is_draft to false
      if (this.cartPreview.billing.is_draft) {
        const profile = this.getBillingPayload();
        profile.is_draft = false;
        this.$store.commit('payments/updateBillingProfile', { profile });
        return this.$store.dispatch('payments/updateBillingProfile').then(() => {
          this.updateResult({ kind: CheckoutResultKind.VerificationRequired });
        });
      }

      console.log('There is nothing to change');
      this.markComplete();
      return null;
    },
  },
};
</script>
