<template>
  <div>
    <div>
      <PayByStripe
        :currency= "'USD'"
        :amount="9"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
</style>
<script>
import { mapState } from 'vuex';
import { allMetaMixins } from '@/mixin/meta';

// import BillingInfo from '@/components/PaymentsHandle/PayByStripe.vue';

export default {
  mixins: allMetaMixins(),
  title: 'Test Payments',
  computed: {
    ...mapState({
      bootData: (state) => state.boot.instance,
    }),
  },
};
</script>
