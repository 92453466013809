<template>
  <div class="progress-bar__container"
  :class="['progress-bar__container', (isAntsProducts ? 'ants-product-progress-bar' : '')]">
    <div class="progress-bar__content">
      <div class="image-wrapper" :class="cartPreview.lines && cartPreview.lines[0]
        && cartPreview.lines[0].product ? cartPreview.lines[0].product : 'paygo'">
        <template v-if="!isAntsProducts">
          <img v-if="packageDetails"
          src="@/assets/payment/package-img.png" class="package-img"/>
          <img v-else src="@/assets/payment/paygo-img.png" />
        </template>
        <template v-else>
          <img src="@/assets/ants/cart-bg.png" class="package-img"/>
        </template>
      </div>
      <div class="progress-bar__wrapper">
        <div class="plan-details-container">
          <div v-if="cartPreview && cartPreview.project && cartPreview.project.project_license
            && cartPreview.project.key !== 'RS_P_1430486429854732337'"
             class="plan-details"
            :class="(cartPreview.future_items
            && cartPreview.future_items[0]
            && !packageDetails) || packageDetails ? '' : 'hide'">
            <div>
              <span class="label">Plan</span>
              <p v-if="packageDetails">
                <rz-markdown v-if="packageDetails" :text="cartPreview.lines[0].name" class="plan-name"></rz-markdown>
              </p>
              <p v-else-if="cartPreview && cartPreview.project
              && cartPreview.project.project_license[0] && cartPreview.project.project_license[0].common_name">
                {{cartPreview.project.project_license[0].common_name}}</p>
              <template v-if="!isAntsProducts">
                <router-link :to="{ name: 'rsPricing' }" v-if="!packageDetails"  target="_blank">
                  View plan details
                </router-link>
                <span @click="showPopup()" v-else-if="packageDetails && !wizard.step.isReviewPlan"
                  class="view-details-btn">
                  View plan details
                </span>
              </template>
              <template v-else>
                <a :href="antsConsoleLink()"
                  class="view-details-btn">
                  View plan details
                </a>
              </template>
            </div>
            <div v-if="packageDetails && cartPreview && cartPreview.lines && cartPreview.lines[0] &&
             cartPreview.cart_choices && cartPreview.cart_choices.length <= 0">
              <template v-if="!cartPreview.lines[0].unit_label || cartPreview.lines[0].unit_label === ''">
                <span class="label">{{cartPreview.lines[0].product_type === 'tournament_package' ?
                  'For entire tournament' : 'Billed Annually'}}</span>
              </template>
              <span v-else class="label">{{cartPreview.lines[0].unit_label}}</span>
              <p class="price">
                {{formatCurrency(cartPreview.lines[0].line_total, cartPreview.lines[0].currency)}}</p>
            </div>
            <div v-if="!packageDetails && cartPreview.cart_choices && cartPreview.cart_choices.length <= 0">
              <span class="label">Plan cost
                {{wizard.step.isProjectName && !packageDetails
                && (cartPreview && cartPreview.project && cartPreview.project.project_license[0] &&
                cartPreview.project.project_license[0].common_name !== 'Standard') ? 'billing cycle' : ''}}
              </span>
              <div class="dropdown-wrapper" v-if="wizard.step.isProjectName && !packageDetails
                && (cartPreview && cartPreview.project && cartPreview.project.project_license[0] &&
                cartPreview.project.project_license[0].common_name !== 'Standard')">
                <transition name="slide">
                  <select
                    class="dropdown"
                    @change="onLicenseChange($event)"
                  >
                    <template
                      v-for="(option, index) in options"
                      >
                      <option class="dropdown__list"
                        :key="index"
                        :value="option.key"
                        :selected="(cartPreview &&
                          cartPreview.license_purchase_detail &&
                          cartPreview.license_purchase_detail.renewal_duration === option.key)"
                        :class="{'selected': (cartPreview &&
                          cartPreview.license_purchase_detail &&
                          cartPreview.license_purchase_detail.renewal_duration === option.key)}"
                      >
                        {{option.value}}
                      </option>
                    </template>
                  </select>
                </transition>
              </div>
              <template v-for="item in cartPreview.future_items">
                <p v-if="item.kind === 'enterprise_future_month'" :key="item.key" class="price">
                  {{formatCurrency(item.amount, item.currency)}}
                </p>
              </template>
              <!-- <p v-if="cartPreview.license_purchase_detail
                && cartPreview.license_purchase_detail.amount_saved"
                class="saved-price">You're saving {{formatCurrency(
                cartPreview.license_purchase_detail.amount_saved, cartPreview.currency)}}</p> -->
            </div>
          </div>
          <div v-if="packageDetails && cartPreview.cart_choices && cartPreview.cart_choices.length > 0"
            class="billing-cycle-container">
            <template v-if="billingCycles.includes(cartPreview.lines[0].quantity)">
              <span class="label">Billing cycle</span>
              <div class="form-group custom-radio" v-for="cartChoice in cartPreview.cart_choices"
                :key="cartChoice"
              >
                <div class="cart-choices"
                  :class="[selectedQuantity === cartChoice.quantity ? 'active' : '']"
                  @click="onChange(cartChoice.quantity)"
                >
                  <div>
                    <div class="checkbox-container">
                      <input
                        type="radio"
                        :v-model="selectedQuantity"
                        :value="cartChoice.quantity"
                        :checked="cartChoice.quantity === selectedQuantity"
                      />
                      <span class="checkmark"></span>
                    </div>
                    <div class="billing-cycle">
                      <span>{{cartChoice.preview_name === 'Half-Yearly' ? '6 Months' : cartChoice.preview_name}}</span>
                    </div>
                  </div>
                  <div class="price-section">
                    <p class="price">{{formatCurrency(cartChoice.line_total, cartPreview.lines[0].currency)}}</p>
                    <p class="saved-price" v-if="cartChoice.discount.amount_saved > 0">
                      Save {{formatCurrency(cartChoice.discount.amount_saved,
                      cartPreview.lines[0].currency)}}</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-if="cartPreview.project.project_license
            && cartPreview.project.key === 'RS_P_1430486429854732337'" class="plan-details">
            <div>
              <span class="label">Plan</span>
              <p>
                Enterprise C13-KC
              </p>
            </div>
          </div>
          <div v-if="cartPreview.project.name" class="project-name"
          :class="(cartPreview.future_items
            && cartPreview.future_items[0]
            && !packageDetails) || packageDetails ? '' : 'remove-padding'">
            <span v-if="isAntsProducts" class="label">Account Name</span>
            <span v-else class="label">Project Name</span>
            <p>{{cartPreview.project.name }}</p>
          </div>
          <div v-if="cartPreview && cartPreview.lines && cartPreview.lines[0]
            && cartPreview.lines[0].expected_plan_start_date &&
          cartPreview.lines[0].product_type !== 'tournament_package'" class="plan-validity">
            <span class="label">Plan Validity</span>
            <p>
             <template v-if="$route.query.invoice === '1735308077483298817'
              && cartPreview.project.key === 'RS_P_1688479013640736772'">
                Dec 17 2023
              </template>
              <template v-else>
                {{  toDate(cartPreview.lines[0].expected_plan_start_date)}}
              </template>
              -
              <span v-if="cartPreview.lines[0].product_type === 'association_package'
                && cartPreview.lines[0].days_of_access === 365">
                {{ addMonth(cartPreview.lines[0].expected_plan_start_date, 12) }}
              </span>
              <span v-else>{{addMonth(cartPreview.lines[0].expected_plan_start_date, selectedQuantity) }}</span>
            </p>
             <div class="sub-heading">
              <span>Please note that your late renewal will incur charges from the original due date.</span>
             </div>
          </div>
        </div>
        <div class="additional-info" v-if="!packageDetails">
          <svg><use v-bind="{'xlink:href':'#help-icon-new'}"></use></svg>
          <p>
            Additional charges are applicable for
            <router-link :to="{name: 'rsPricing'}" target="_blank">API Usage</router-link> every month
          </p>
        </div>
        <div class="loading-state-placeholder">
          <div
            :class="{active: cartPreviewState.status.isLoading}"
            class="msg animate-loading-state">Updating...</div>
        </div>
        <div
          class="cart-preview"
          :class="{updating: cartPreviewState.status.isLoading}"
        >
          <div
            v-if="cartPreview.future_items && cartPreview.future_items.length
             && cartPreview.project.key !== 'RS_P_1430486429854732337'"
            class="due-date__container"
          >
            <p
              v-for="(line, i) in cartPreview.future_items"
              :key="line.kind + i"
            >
              <span
                class="due-date"
                v-if="line.due_date"
              >Due on {{ toDate(line.due_date) }}</span>
              <span
                class="due-date"
                v-else-if="cartPreview.future_items.length === 1"
              >Every
                {{cartPreview && cartPreview.license_purchase_detail
                  && cartPreview.license_purchase_detail.renewal_duration === 'annual' ? 'year' : 'month'}}</span>
              <span
                class="due-date"
                v-else
              >Every month thereafter</span>
              <span class="due-amount">
                {{
                  formatCurrency(
                    line.amount,
                    line.currency
                  )
                }}
                <sup v-if="line.due_date">1</sup>
              </span>
            </p>
          </div>
          <div class="cart-preview-container animate-loading-state">
            <div
              v-if="cartPreview.grand_total >= 0"
              class="total-bill__container"
            >
              <div
                v-if="!packageDetails"
                class="payment-details__container"
              >
                <h3>Payments</h3>
                <div
                  class="upfront-amount"
                  v-for="(line, i) in cartPreview.lines"
                  :key="i"
                >
                  <span>{{line.preview_name}}</span>
                  <span class="align-right">
                    {{
                      formatCurrency(
                        line.line_total,
                        line.currency
                      )
                    }}
                  </span>
                </div>
              </div>
              <div
                v-for="(line, i) in cartPreview.discount_lines"
                :key="line.name + i"
                class="discount-lines"
              >
                <span>{{line.name}}</span>
                <span class="align-right">
                  {{ formatCurrency(line.amount, cartPreview.currency) }}
                </span>
              </div>
              <div
                v-for="(line, i) in cartPreview.coupon_discount_lines"
                :key="line.name + i"
                class="discount-lines"
              >
                <span>{{line.name}}</span>
                <span class="align-right">
                  {{ formatCurrency(line.line_total, cartPreview.currency) }}
                </span>
              </div>
              <div
                v-if="(cartPreview && cartPreview.coupon_attach_status
                && !cartPreview.coupon_attach_status.attached)
                && cartPreview.coupon_attach_status.error_msg"
                class="coupon-error"
              >
                Oops! {{cartPreview.coupon_attach_status.error_msg}}!
              </div>
              <div
                class="tax-lines"
                :class="!packageDetails
                ? '' : 'add-border'"
              >
                <p v-for="(line, i) in cartPreview.tax_lines" :key="line.name + i"
                  :class="'tax-line-'+ i">
                  <span>{{line.name}}</span>
                  <span class="align-right">
                    {{ formatCurrency(line.amount, cartPreview.currency) }}
                  </span>
                </p>
              </div>
              <div class="total" v-if="cartPreview.grand_total >= 0" >
                <span>Pay now</span>
                <span class="align-right">
                  {{ formatCurrency(cartPreview.grand_total, cartPreview.currency) }}
                </span>
              </div>
              <div
                v-for="(line, i) in cartPreview.extra_credit_lines"
                :key="line.name + i"
                class="discount"
              >
                <span class="align-left">{{line.preview_name}}</span>
                <span class="align-right">
                  {{
                    formatCurrency(
                      line.extra_credits_amount,
                      cartPreview.currency)
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="description" v-if="cartPreview.project.key !== 'RS_P_1430486429854732337'">
      <p
        v-for="(line, i) in cartPreview.future_items"
        :key="line.kind + i"
      >
        <sup v-if="line.due_date">1</sup>
        <template
          v-if="line.due_date"
        >
          This is the prorated cost for the current month</template>
      </p>
    </div>
    <template v-if="packageDetails && productDetails">
      <div class="popup-wrapper" v-if="openPopup" @click.self="closePopup()">
        <div class="popup" id="popup">
          <div class="popup-header">
            <svg @click="closePopup()"><use v-bind="{'xlink:href':'#close-btn'}"></use></svg>
          </div>
          <PackagePopup  v-if="packageData"
            :planInfo="packageData && packageData[0]" :currency="currency" :isPayment="true"/>
        </div>
      </div>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.progress-bar__container {
  width: size(325);
  font-family: var(--rz-hero-font);
  border-radius: size(8);
  margin-bottom: size(100);

  @media screen and (min-width: $breakpoint-md) {
    margin-top: size(135);
  }

  .coupon-error {
    color: var(--onboard-error-color);
    font-weight: 500;
    padding-bottom: size(10);
    text-align: center;
    transition: opacity 0.8s linear;
    animation: blinker 1.5s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .image-wrapper {
    border-top-left-radius: size(8);
    border-top-right-radius: size(8);
    background: #93E8EB;
    height: size(80);
    &.paygo {
      background: linear-gradient(0deg, #97E6AB, #97E6AB), #79CEA5;
    }
    &.rs_package_icc_wc_t20_2022_6f3,  &.rs_package_icc_wc_t20_2022_177 {
      background: #A9C6FC;
    }
    &.rs_package_iplt20_2022_689 {
      background: #93E8EB;
    }
    &.rs_package_c__board__icc__c2ab7ee61_1ad, &.rs_package_c__board__icc__c2ab7ee61_72a {
      background: #6FCAF2;
    }
    img {
      width: size(200);
      &.package-img {
        width: size(100);
      }
    }
  }

  .popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    .popup {
      position: fixed;
      z-index: 1000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: size(2);
      width: size(300);
      height: size(450);
      overflow: scroll;
      @media screen and (min-width: $breakpoint-md) {
        width: size(680);
        height: auto;
        overflow: auto;
      }
      .popup-header {
        svg {
          position: absolute;
          right: size(20);
          top: size(20);
          width: size(10);
          height: size(10);
          cursor: pointer;
        }
      }
    }
  }

  h2 {
    text-align: center;
    background-color: var(--progress-bar--pointer--Color);
    padding: size(12) 0;
    font-size: size(20);
    line-height: size(22);
    color: var(--master-text);
    font-weight: bold;
    border-top-left-radius: size(6);
    border-top-right-radius: size(6);
    margin-bottom: 0;
    font-family: var(--regular-font);
  }
}
.progress-bar__content {
  position: relative;
  box-shadow: 0 size(1) size(3) rgba(51, 51, 51, 0.1),
    0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
  border-radius: size(8);
}
.additional-info {
  padding: size(15) 0;
  margin: 0 size(20);
  border-top: size(1) dashed #C4C4C4;
  svg {
    display: inline-block;
    width: size(14);
    height: size(14);
    margin-right: size(14);
  }
  p {
    display: inline-block;
    vertical-align: top;
    width: 88%;
    font-weight: 400;
    font-size: size(12);
    line-height: size(17);
    color: var(--rs-black-color);
  }
  a {
    text-decoration: underline;
    &:hover {
      color: var(--rs-black-color);
    }
  }
}
.description {
  p {
    padding-top: size(12);
    font-size: size(14);
    line-height: size(19);
    color: var(--rs-black-color);
    &:first-child {
      padding-top: size(20);
    }
  }
  sup {
    font-weight: 700;
  }
}
sup {
  color: var(--rz-link-color);
  vertical-align: super;
  font-size: size(10);
  font-weight: 700;
}
.progress-bar__wrapper {
  background: var(--rs-white-color);
  border-bottom-left-radius: size(8);
  border-bottom-right-radius: size(8);
  .progress-bar {
    .text-container {
      display: inline-block;
      vertical-align: top;
      height: size(40);
      width: size(220);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      h3 {
        font-size: size(15);
        line-height: size(22);
        font-weight: bold;
        padding-bottom: size(4);
      }
      span {
        font-size: size(14);
        line-height: size(17);
      }
    }
  }
  .form-group {
    &.custom-radio {
      margin-bottom: size(16);
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: size(16);
      }
    }
  }
  .plan-details-container {
    padding: size(20);
    .label {
      font-weight: 500;
      font-size: size(12);
      line-height: size(17);
      color: #777777;
      padding-bottom: size(4);
    }
    .project-name {
      padding-top: size(15);
      &.remove-padding {
        padding-top: 0;
      }
    }

    .plan-validity {
      padding-top: size(15);

      p {
        font-weight: 500;
        font-size: size(15);
        line-height: size(22);
      }

      .sub-heading {
        color: #585757;
        font-weight: normal;
        font-size: size(15);
        line-height: size(19);
        font-weight: normal;
        padding-top: size(6);
      }
    }
    .project-name, .plan-details {
      p, .plan-name {
        font-weight: 500;
        font-size: size(15);
        line-height: size(22);
        color: var(--rs-black-color);
        max-width: size(135);
        word-break: break-word;
        &.price {
          margin-right: 0;
          max-width: initial;
        }
        &.saved-price {
          font-size: size(12);
          line-height: size(17);
          color: #00A656;
          max-width: initial;
          padding-top: size(5);
        }
      }
      a, .view-details-btn {
        display: block;
        cursor: pointer;
        margin-top: size(6);
        font-size: size(12);
        line-height: size(17);
        font-weight: 500;
        width: size(105);
        color: var(--rz-link-color);
      }
    }
    .plan-name {
      span {
        width: 100%;
      }
    }
    .plan-details {
      div {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        &:nth-child(2), &:nth-child(3) {
          text-align: right;
        }
      }
      .dropdown-wrapper {
        display: block;
        text-align: right;
        width: 100%;
        .dropdown {
          width: size(98);
          font-size: size(12);
          font-weight: 500;
          line-height: size(17);
          border: size(1) solid #C4C4C4;
          border-radius: size(8);
          padding: size(8) size(12);
          margin-top: size(6);
          margin-bottom: size(12);
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          background: url('../../assets/dropdown.svg') no-repeat;
          background-size: size(8) size(4);
          background-position: right size(14) bottom size(14);
          background-color: var(--rs-white-color);
          option {
            font-size: size(12);
            line-height: size(17);
          }
        }
      }
      &.hide {
        display: none;
      }
    }
    .billing-cycle-container {
      .label {
        display: block;
        padding-bottom: size(8);
        margin-top: size(16);
      }
      .cart-choices {
        display: flex;
        align-items: start;
        justify-content: space-between;
        padding: size(16);
        border-radius: size(8);
        border: size(1) dashed var(--rs-border-gray-color);

        transition: border 0.5s linear;

        &.active {
          border: size(1) solid #00A656;
        }

        .checkbox-container {
          .checkmark  {
            top: size(20);
            left: size(16);
            border-color: var(--rs-border-gray-color);

            &::after {
              background: #00A656;
            }
          }

          input {
            left: 0;
            top: 0;
            height: size(74);
          }
        }
        .price-section {
          text-align: right;
          .saved-price {
            font-size: size(12);
            line-height: size(17);
            font-weight: 500;
            color: #00A656;
            max-width: initial;
            margin-top: size(2);
          }

          .price {
            font-weight: 700;
            font-size: size(15);
            line-height: size(21);
            color: var(--rs-black-color);
            margin-top: 0;
          }
        }

        .billing-cycle {
          margin-left: size(24);
          p {
            font-size: size(12);
            line-height: size(17);
            color: #777777;
            padding-bottom: size(4);
            margin-top: 0;
          }
          span {
            font-weight: 500;
            font-size: size(15);
            line-height: size(21);
            color: var(--rs-black-color);
          }
        }
      }
    }
  }
  .due-date__container {
    margin: 0 size(20) 0;
    padding: size(15) 0;
    border-top: size(1) dashed #C4C4C4;;
    p {
      display: flex;
      align-content: space-between;
      margin-bottom: size(16);
      &:last-child {
        margin-bottom: 0;
      }
    }
    div{
      &:first-child {
        padding-bottom: size(16);
      }
    }
    span {
      font-size: size(15);
      line-height: size(19);
      vertical-align: sub;
      &.due-amount {
        flex-basis: 35%;
        text-align: right;
        align-self: flex-end;
        font-weight: 600;
      }
      &.due-date {
        flex: 1;
        text-align: left;
      }
    }
  }
  .payment-details__container {
    h3 {
      font-size: size(15);
      line-height: size(22);
      font-weight: 600;
    }
    .align-right {
      float: right;
    }
    .promo-code {
      padding: size(11) size(25);
      background: var(--progress-bar--promo--Color);
      font-size: size(16);
      line-height: size(19);
      font-weight: bold;
    }
    .upfront-amount {
      padding: size(12) 0;
      font-size: size(15);
      line-height: size(22);
      span {
        display: inline-block;
        &:first-child {
          max-width: size(160);
        }
      }
      .align-right {
        font-size: size(15);
        line-height: size(22);
      }
    }
  }
  .total-bill__container {
    margin: 0 size(20) size(20);
    padding: 0 0 size(20);
    font-size: size(15);
    line-height: size(22);
    .discount-lines,.tax-lines {
      padding-bottom: size(10)
    }
    .tax-lines {
      &.add-border {
        padding-top: size(15);
        border-top: size(1) dashed #C4C4C4;
        &:empty {
          padding-top: 0;
          border-top: none;
        }
      }
      p {
        &:last-child {
          padding-top: size(15);
        }
        &.tax-line-0 {
          padding-top: 0;
        }
      }
    }
    .payment-details__container {
      padding-top: size(15);
      border-top: size(1) dashed #C4C4C4;
    }
    .total {
      margin-top: size(3);
      border-top: size(1) dashed #C4C4C4;
      padding: size(20) 0 0;
      span {
        font-size: size(18);
        line-height: size(22);
        &.align-right {
          font-weight: 700;
        }
      }
    }
    .align-right {
      color: var(--progress-bar--pointer--Color);
      float: right;
    }
    .discount {
      padding: size(15) 0 0;
      font-size: size(16);
      line-height: size(19);
      font-weight: normal;
      .align-left {
        display: inline-block;
        max-width: size(170);
      }
      .align-right {
        font-size: size(16);
        line-height: size(22);
        color: var(--ca-title-color);
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  .animate-loading-state {
      transition: all 600ms cubic-bezier(.95,.05,.8,.04);
      // transition-delay: 100ms;
  }

  .loading-state-placeholder {
    text-align: center;
    font-size: size(12);
    line-height: 1.2;
    text-transform: uppercase;
    .msg {
      display: none;
      &.active {
        display: block;
      }
    }
  }

  .cart-preview {
    &.updating {
      .cart-preview-container {
        opacity: 0.5;
        transform: scale(0.95);
      }
    }
  }
}
.ants-product-progress-bar {
  .image-wrapper {
    background: #303943;
  }

  .additional-info {
    color: var(--rs-white-color);
  }

  .loading-state-placeholder {
    background: var(--rz-ants-primary-bg);
    color: var(--rs-white-color);
  }

  .progress-bar__wrapper {
    background: var(--rz-ants-primary-bg);
    .plan-details-container, .cart-preview {
      background: var(--rz-ants-primary-bg);
    }

    .cart-preview {
      .cart-preview-container {
        .total-bill__container {
          .tax-lines , .total, .discount-lines {
            color: var(--rs-white-color);
            border-color: var(--rz-ants-color-border);
          }
        }
      }
    }
    .plan-details-container {
      .plan-details {
        .view-details-btn {
          color: #F5FF78;
          display: none;
        }
        span {
          color: var(--rz-ants-color-text1);
        }
        p, .plan-name  {
          color: var(--rs-white-color);
        }
      }
      .project-name {
        span {
          color: var(--rz-ants-color-text1);
        }

        p {
          color: var(--rs-white-color);
        }
      }
    }
  }
}

</style>
<script>
import { mapState } from 'vuex';
import Config from '@/config';
import PackagePopup from '@/components/PackagePopup.vue';


export default {
  components: {
    PackagePopup,
  },
  data() {
    return {
      openPopup: false,
      packageData: [],
      options: {
        // monthly: {
        //   value: 'Monthly',
        //   key: 'monthly',
        // },
        annual: {
          value: 'Annually',
          key: 'annual',
        },
      },
      billingCycles: [1, 6, 12],
      selectedQuantity: parseInt(this.$route.query.quantity, 10) || 1,
      isAntsProducts: this.$route.query.show_ants_product,
    };
  },
  watch: {
    $route() {
      this.isAntsProducts = this.$route.query.show_ants_product;
    },
  },
  mounted() {
    this.fetchPackagePricing();
    if (this.$route.query.quantity
      && (this.$route.query.couponKey === 'null' || this.$route.query.couponKey === 'undefined')) {
      this.$store.dispatch('payments/updateCart',
        {
          quantity: parseInt(this.$route.query.quantity, 10),
          ...(this.$route.query.renewal_key && {
            projectProductKey: this.$route.query.renewal_key,
          }),
        });
    }
    if (this.$route.query.quantity
      && (this.$route.query.couponKey !== 'null' && this.$route.query.couponKey !== 'undefined')) {
      this.$store.dispatch('payments/updateCart', {
        quantity: parseInt(this.$route.query.quantity, 10),
        couponCode: this.$route.query.couponKey,
        ...(this.$route.query.renewal_key && {
          projectProductKey: this.$route.query.renewal_key,
        }),
      });
    }
  },
  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      currency: (state) => state.user.activeCurrency,
      licenseInfo: (state) => state.payments.wizard.licenses,
      cartPreview: (state) => state.payments.cartPreview,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      cartPreviewState: (state) => state.payments.cartPreviewState,
      packageInfo: (state) => state.paygo.package && state.paygo.package.packageInfo,
    }),
    packageDetails() {
      if ((this.cartPreview && this.cartPreview.project
        && this.cartPreview.project.project_license
        && this.cartPreview.project.project_license[0]
        && this.cartPreview.project.project_license[0].product) === 'rs_package') {
        return true;
      }
      return false;
    },
    productDetails() {
      if (this.packageInfo) {
        const packageKey = this.$route.query && this.$route.query.package;
        for (let i = 0; i < this.packageInfo.length; i += 1) {
          if (this.packageInfo[i].key === packageKey) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.packageData.push(this.packageInfo[i]);
          }
        }
      }
      return true;
    },
  },
  methods: {
    showPopup() {
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'hidden';
    },
    onChange(qty) {
      this.cartPreviewInput.quantity = qty;
      this.selectedQuantity = qty;
      this.$route.query.quantity = qty;
      this.$store.dispatch('payments/fetchCartPreview');
    },
    antsConsoleLink() {
      return `${Config.antsConsole}pricing`;
    },
    onLicenseChange() {
      let license;
      if (this.cartPreview && this.cartPreview.project && this.cartPreview.project.project_license
        && this.cartPreview.project.project_license[0]) {
        const licenseInfo = this.cartPreview.project.project_license[0];
        if (licenseInfo === 'package') {
          license = 'rs_package';
        } else {
          license = licenseInfo.product;
        }
        if (this.cartPreview && this.cartPreview.future_items
          && this.cartPreview.future_items.length > 1) {
          this.cartPreview.future_items = this.cartPreview.future_items.slice(1);
        }
        this.cartPreviewInput.prepayAmount = 50;
        this.cartPreviewInput.prepayAmountKey = license;
        this.cartPreviewInput.selectedLicenseKey = license;
        this.$route.query.license = license;
        this.$store.dispatch('payments/fetchCartPreview');
        this.$store.commit('payments/updateWizard', { selectedLicense: license });
        this.$store.commit('payments/updateCartPreviewParam', {
          selectedLicense: licenseInfo.common_name,
        });
      }
    },
    closePopup() {
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'auto';
    },
    fetchPackagePricing() {
      return this.$store.dispatch('paygo/fetchPackagePricing');
    },
  },
};
</script>
