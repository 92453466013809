import { render, staticRenderFns } from "./ChooseProject.vue?vue&type=template&id=2e08e864&scoped=true&"
import script from "./ChooseProject.vue?vue&type=script&lang=js&"
export * from "./ChooseProject.vue?vue&type=script&lang=js&"
import style0 from "./ChooseProject.vue?vue&type=style&index=0&id=2e08e864&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e08e864",
  null
  
)

export default component.exports