<template>
  <div class="about">
    <div v-if="isError">
      <h2>{{errorMessage}}</h2>
      <a href="/">Go Home</a>
    </div>
    <div class="content placeholder" v-else>
      <CricketLoadingPlaceholder :state="pageState"/>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import CookieManager from '@/core/utils/cookieManager';

import { RequestState, RequestStateStatus } from '@/api/core';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';

export default {
  name: 'loginresponse',
  components: {
    CricketLoadingPlaceholder,
  },
  data() {
    return {
      isError: false,
      errorMessage: '',
      pageState: new RequestState(RequestStateStatus.Loading),
    };
  },
  mounted() {
    const { token } = this.$route.query;
    console.log('token', token);
    if (token === undefined || token === null || token === '') {
      this.isError = true;
      this.errorMessage = 'Token Missing';
    } else if (token === 'access_denied') {
      this.isError = true;
      this.errorMessage = 'You are not authorised to access this page!';
    } else {
      const expires = moment().add(1, 'day');
      const newToken = CookieManager.setItem('rzAccessToken', token, expires.toDate(), '/');
      const nextUrl = this.$route.query.next_url || '/';
      this.$store.commit('user/updateAccessToken', { newToken });
      this.$router.push({ path: nextUrl });
    }
  },
};
</script>
